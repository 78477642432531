import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  dismiss() {
    this.snackBar.dismiss();
  }

  error(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'error',
        message: message,
      },
    });
  }

  warning(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'warning',
        message: message,
      },
      duration: 3000,
    });
  }

  success(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'success',
        message: message,
      },
      duration: 3000,
    });
  }

  info(message: string) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'info',
        message: message,
      },
      duration: 3000,
    });
  }
}
