<div class="notification">
  @switch (data.type) {
    @case ('error') {
      <mat-icon class="notification__icon"> error </mat-icon>
    }
    @case ('warning') {
      <mat-icon class="notification__icon"> warning </mat-icon>
    }
    @case ('success') {
      <mat-icon class="notification__icon"> check </mat-icon>
    }
    @case ('info') {
      <mat-icon class="notification__icon"> info </mat-icon>
    }
  }

  <div class="notification__message">{{ data.message }}</div>

  <button mat-icon-button (click)="ref.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
