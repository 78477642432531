import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RecordListResponse } from '@models';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class RecordService {
  constructor(private http: HttpClient) {}

  public getRecords(
    datasetId: string,
    parentId: string | null,
    skip: number,
    take: number,
    doCount: boolean,
    searchQuery: string | null,
  ): Observable<RecordListResponse> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/record/`;
    let params = new HttpParams().set('skip', skip).set('take', take);

    if (doCount) {
      params = params.set('count', true);
    }

    if (parentId !== null) {
      params = params.set('parentId', parentId);
    }

    if (searchQuery !== null) {
      params = params.set('q', searchQuery);
    }

    return this.http.get<RecordListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }
}
