import { AbstractControl, ValidationErrors } from '@angular/forms';

export function EmailDomainValidator(
  control: AbstractControl,
): ValidationErrors | null {
  return (control.value as string).includes('@npolar.no')
    ? {
        domain: true,
      }
    : null;
}
