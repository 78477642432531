import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, Event } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { User } from '@models';
import { AuthService } from '@services';
import { Observable, Subscription, filter, map } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Output()
  toggleMenu = new EventEmitter();

  public currentRoute: string;
  public currentUser: User | null;
  public isAccountMenuOpen: boolean;
  public isMobile$: Observable<boolean>;

  private subscriptions: Subscription[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthService,
    breakpointObserver: BreakpointObserver,
  ) {
    this.subscriptions = [];
    this.currentRoute = '';
    this.currentUser = null;
    this.isAccountMenuOpen = false;
    this.isMobile$ = breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((result: BreakpointState) => result.matches));
  }

  ngOnInit() {
    const urlSubscription = this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        map((e: Event) => {
          return (e as NavigationEnd).urlAfterRedirects.split('?')[0];
        }),
      )
      .subscribe((url: string) => {
        this.currentRoute = url;
      });

    this.subscriptions.push(urlSubscription);

    const userSubscription = this.authService.user$.subscribe(
      (user: User | null) => {
        this.currentUser = user;
      },
    );
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((hasLoggedIn: boolean) => {
      if (!hasLoggedIn) {
        return;
      }

      this.router.navigateByUrl('/');
    });
  }

  logOut() {
    this.authService.logOut();
  }
}
