<div class="npdc-layout">
  <div class="npdc-layout__toolbar">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"> </app-breadcrumb>
  </div>
  <div class="npdc-layout__content">
    <h1>About the Norwegian Polar Data Centre</h1>
    <p>
      The Norwegian Polar Data Centre is the data repository of the Norwegian
      Polar Institute. Our mission is to publish scientific datasets and to
      manage the data holdings of the Institute. The Data Centre provides access
      to scientific data, environmental monitoring data, and topographic and
      geological map data from the polar regions.
    </p>
    <p>
      Our metadata records follow internationally recognised standard formats to
      provide exchange with other data centres. Unless temporarily embargoed to
      facilitate publication, all our published data are publicly available
      under an open license.
    </p>
    <h2>Our mission</h2>
    <p>
      Our mission is founded on the Norwegian Government’s policy for open
      research data, the data policy of the Norwegian Polar Institute and, for
      Antarctic datasets, Article III 1(c) of the Antarctic Treaty, which states
      that “scientific observations and results from Antarctica shall be
      exchanged and made freely available”. The Norwegian Polar Data Centre
      serves as the National Antarctic Data Centre (NADC) for Norway.
    </p>

    <h2>Links</h2>

    <ul>
      <li>
        <a href="/assets/policy/NPI-data-policy-en_GB.pdf" target="_blank"
          >Data policy (English)</a
        >
      </li>
      <li>
        <a
          href="/assets/policy/NP-datapolitikk-nb_NO.pdf"
          target="_blank"
          hreflang="nb-NO"
          >Datapolitikk (norsk)</a
        >
      </li>
    </ul>
    <div>
      <a
        href="https://www.re3data.org/repository/r3d100012291"
        class="nav-link"
        rel="nofollow"
        target="_blank">
        <picture>
          <source
            srcset="/assets/re3data-lg-dark.svg"
            media="(prefers-color-scheme: dark)" />
          <img
            src="/assets/re3data-lg-light.svg"
            alt="NPDC is registered with re3data.org." />
        </picture>
      </a>
    </div>
    <div>
      <a
        href="https://fairsharing.org/10.25504/FAIRsharing.4dbb9e"
        class="nav-link"
        rel="nofollow"
        target="_blank">
        <picture>
          <source
            srcset="/assets/fairsharing-logo-dark.svg"
            media="(prefers-color-scheme: dark)" />
          <img
            src="/assets/fairsharing-logo.svg"
            alt="fairsharing.org. Standards, databases, policies."
            style="width: 20rem" />
        </picture>
      </a>
    </div>

    <h2>Contact</h2>

    <ul>
      <li> Email: <a href="mailto:data@npolar.no"> data&#64;npolar.no </a> </li>
      <li>
        Homepage:
        <a href="https://www.npolar.no/" target="_blank">
          Norwegian Polar Institute
        </a>
      </li>
    </ul>

    <address>
      Norwegian Polar Institute<br />
      Fram Centre<br />
      Post box 6606 Stakkevollan<br />
      N-9296 Tromsø<br />
      Norway
    </address>

    <h2>Build info</h2>
    <p>Build ID: {{ buildId }}</p>
  </div>
</div>
