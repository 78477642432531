import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class ISODateAdapter extends NativeDateAdapter {
  public override getFirstDayOfWeek(): number {
    return 1;
  }

  public override format(
    date: Date,
    displayFormat?: { year?: string; month?: string; day?: string },
  ): string {
    if (!date) {
      return '';
    }

    const value = new Date(date.setHours(12)).toISOString().slice(0, 10);

    // calendar (date picker) passes these
    if (displayFormat?.year && displayFormat?.month && !displayFormat?.day) {
      return value.slice(0, 7);
    }

    return value;
  }
}
