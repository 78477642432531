export enum FormState {
  None = 0,
  Valid = 1,
  Invalid = 2,
}

export enum NetworkState {
  None = 0,
  Pending = 1,
  Success = 2,
  Error = 3,
}
