import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Permission, PermissionListResponse } from '@models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private http: HttpClient) {}

  getPermissions(
    datasetId: string,
    skip: number,
    take: number,
    count: boolean,
  ): Observable<PermissionListResponse> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/permission/`;
    let params = new HttpParams().set('skip', skip).set('take', take);

    if (count) {
      params = params.set('count', count);
    }

    return this.http.get<PermissionListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }

  createPermission(
    datasetId: string,
    permission: Permission,
  ): Observable<Permission> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/permission/`;

    return this.http.post<Permission>(endpoint, permission, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  updatePermission(
    datasetId: string,
    permission: Permission,
  ): Observable<Permission> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/permission/${permission.userId}`;

    const payload = {
      mayRead: permission.mayRead,
      mayUpdate: permission.mayUpdate,
      mayDelete: permission.mayDelete,
    } as Permission;

    return this.http.put<Permission>(endpoint, payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  deletePermission(datasetId: string, permissionId: string): Observable<void> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/permission/${permissionId}`;
    return this.http.delete<void>(endpoint);
  }

  getRoleFromPermissions(
    permission: Permission,
  ): 'Reader' | 'Contributor' | 'Owner' {
    if (permission?.mayDelete) {
      return 'Owner';
    }

    if (permission?.mayUpdate) {
      return 'Contributor';
    }

    return 'Reader';
  }

  getPermissionsFromRole(role: 'Reader' | 'Contributor' | 'Owner'): {
    mayRead: boolean;
    mayUpdate: boolean;
    mayDelete: boolean;
  } {
    switch (role) {
      case 'Owner':
        return {
          mayRead: true,
          mayUpdate: true,
          mayDelete: true,
        };
      case 'Contributor':
        return {
          mayRead: true,
          mayUpdate: true,
          mayDelete: false,
        };
      default:
        return {
          mayRead: true,
          mayUpdate: false,
          mayDelete: false,
        };
    }
  }
}
