<div class="leaflet-container npdc-outline">
  <app-leaflet
    #leafletComponent
    [geojson$]="geojson$"
    [isReadOnly]="false"
    (geometryChanged)="onChange($event)"></app-leaflet>
</div>
<div class="hint"
  >Use the controls on the map to mark relevant areas. To add a more precise
  geometry,
  <button
    mat-button
    appPreventDefault
    (click)="leafletComponent.openGeoJsonDialog()"
    >click here</button
  >
</div>
