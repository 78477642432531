<div class="prefix">
  @if (isLoading$ | async) {
    <mat-spinner matPrefix [diameter]="24"></mat-spinner>
  } @else {
    <mat-icon>search</mat-icon>
  }
</div>

<input
  type="text"
  class="input"
  placeholder="{{ placeholder }}"
  [tabindex]="tabindex"
  (keydown.enter)="blur($event)"
  (keydown.escape)="blur($event)"
  (focus)="focusChange.next({ isFocused: true, event: $event })"
  (focusout)="focusChange.next({ isFocused: false, event: $event })"
  [formControl]="inputFormControl" />

@if (inputFormControl.value) {
  <button
    mat-icon-button
    (click)="inputFormControl.reset()"
    matTooltip="Clear search"
    ><mat-icon>close</mat-icon></button
  >
}

<ng-content></ng-content>
