<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  @if (data.content) {
    <p>{{ data.content }}</p>
  }
  @if (data.template) {
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
  }
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button (click)="dialogRef.close(true)">Ok</button>
  <button mat-button mat-dialog-close>Cancel</button>
</div>
