import { Injectable } from '@angular/core';
import { User, UserListResponse } from '@models';
import { CacheService } from '@services';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private cache: CacheService,
    private http: HttpClient,
  ) {}

  createUser(email: string): Observable<null> {
    return this.http.post<null>(
      `${environment.komainuHost}/account/`,
      {
        linkPrefix: `${window.location.origin}/account/password?token=`,
        email: email,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  updateUserStatus(id: string, active: boolean): Observable<null> {
    return this.http.put<null>(
      `${environment.komainuHost}/account/${id}`,
      {
        active: active,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  triggerAccountRecovery(email: string): Observable<null> {
    return this.http.post<null>(
      `${environment.komainuHost}/recover/`,
      {
        linkPrefix: `${window.location.origin}/account/password?token=`,
        email: email,
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  getUser(id: string): Observable<User | null> {
    if (!this.cache.users.has(id)) {
      this.cache.users.set(id, new BehaviorSubject<User | null>(null));

      return this.http
        .get<User>(`${environment.komainuHost}/account/${id}`, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .pipe(
          tap((user: User | null) => {
            this.cache.users.get(id)?.next(user);
          }),
          catchError((err) => {
            console.error('failed to fetch user', err);
            return of(null);
          }),
        );
    }

    return this.cache.users.get(id)?.asObservable() || of(null);
  }

  getUsers(filter?: string): Observable<UserListResponse> {
    const endpoint = `${environment.komainuHost}/account/`;
    let params;

    if (filter) {
      params = new HttpParams().set('q', filter);
    }

    return this.http.get<UserListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }
}
