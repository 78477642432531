<div class="sidenav">
  <div class="sidenav__header">
    <picture>
      <source
        srcset="/assets/logo_dark.svg"
        media="(prefers-color-scheme: dark)" />
      <img
        src="/assets/logo_light.svg"
        height="160"
        alt="Norwegian Polar Insitute" />
    </picture>
  </div>
  <mat-nav-list class="sidenav__content">
    <a
      mat-list-item
      routerLink="/dataset"
      [activated]="currentRoute.startsWith('/dataset') || currentRoute === '/'">
      Datasets
    </a>
    @if (currentUser$ | async; as user) {
      @if (user.accessLevel === 'admin') {
        <a
          mat-list-item
          routerLink="/admin/users"
          [activated]="currentRoute === '/admin/users'">
          Users
        </a>
      }
    }
    <a
      mat-list-item
      routerLink="/about"
      [activated]="currentRoute === '/about'">
      About
    </a>

    <a
      mat-list-item
      href="#links"
      (click)="$event.preventDefault(); isLinksExpanded = !isLinksExpanded">
      <span matListItemTitle>Links</span>
      <span matListItemMeta>
        @if (!isLinksExpanded) {
          <mat-icon>keyboard_arrow_down</mat-icon>
        } @else {
          <mat-icon>keyboard_arrow_up</mat-icon>
        }
      </span>
    </a>
    @if (isLinksExpanded) {
      <mat-nav-list class="nested-list">
        <a
          mat-list-item
          target="_blank"
          href="https://www.npolar.no/"
          rel="nofollow"
          title="Homepage of the Norwegian Polar Institute.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Norwegian Polar Institute</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://svalbardkartet.npolar.no/"
          rel="nofollow"
          title="Svalbard map viewer with various thematic map layers available.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Svalbard thematic maps</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://topoSvalbard.npolar.no/"
          rel="nofollow"
          title="Topographic map viewer for Svalbard.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Svalbard topographic map</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://topoJanMayen.npolar.no/"
          rel="nofollow"
          title="Topographic map viewer for Jan Mayen.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Jan Mayen topographic map</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://placenames.npolar.no/"
          rel="nofollow"
          title="Registry of official place names.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Arctic &amp; Antarctic place names</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://data.npolar.no/map/archive"
          rel="nofollow"
          title="Digital archive of various historic maps covering Arctic &amp; Antarctic regions.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Historic map archive</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://mosj.no/en/"
          rel="nofollow"
          title="Environmental monitoring information for Svalbard &amp; Jan Mayen.">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>MOSJ (environmental monitoring)</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://data.npolar.no/geology/sample"
          rel="nofollow"
          title="Geological sample archive">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Geological samples</span>
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://data.npolar.no/vessel/"
          rel="nofollow"
          title="Kjell-G. Kjær's Historic Register of Arctic Vessels">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>Polar vessels</span>
        </a>
      </mat-nav-list>
    }
    <a
      mat-list-item
      href="#resources"
      (click)="
        $event.preventDefault(); isResourcesExpanded = !isResourcesExpanded
      ">
      <span matListItemTitle>Developer resources</span>
      <span matListItemMeta>
        @if (!isResourcesExpanded) {
          <mat-icon>keyboard_arrow_down</mat-icon>
        } @else {
          <mat-icon>keyboard_arrow_up</mat-icon>
        }
      </span>
    </a>
    @if (isResourcesExpanded) {
      <mat-nav-list class="nested-list">
        <a
          mat-list-item
          target="_blank"
          href="https://api.npolar.no/"
          rel="nofollow">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          REST APIs</a
        >
        <a
          mat-list-item
          target="_blank"
          href="https://geodata.npolar.no/"
          rel="nofollow">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          Geodata services
        </a>
        <a
          mat-list-item
          target="_blank"
          href="https://oai.data.npolar.no/oai-pmh/?verb=Identify">
          <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
          <span matListItemTitle>OAI-PMH API</span>
        </a>
      </mat-nav-list>
    }
    <a mat-list-item target="_blank" href="https://docs.data.npolar.no/">
      <span matListItemMeta><mat-icon>open_in_new</mat-icon></span>
      <span matListItemTitle>Help</span>
    </a>
  </mat-nav-list>
</div>
