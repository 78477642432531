import { Injectable } from '@angular/core';
import { User } from '@models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  public users: Map<string, BehaviorSubject<User | null>>;

  constructor() {
    this.users = new Map<string, BehaviorSubject<User | null>>();
  }
}
