import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private readonly MAX_LENGTH = 15;

  constructor(private title: Title) {}

  public setTitle(...values: string[]) {
    values.push('NPDC');

    for (let i = 0; i < values.length - 1; i++) {
      if (values[i].length > this.MAX_LENGTH) {
        values[i] = values[i].substring(0, this.MAX_LENGTH) + '...';
      }
    }

    this.title.setTitle(values.join(' - '));
  }
}
