@if (parentIds.length) {
  <div class="breadcrumbs">
    <div class="breadcrumbs__item">
      <button
        mat-button
        (click)="filterForm.patchValue({ parentId: '' }); parentIds = []"
        >Index</button
      >
      <span>/</span>
    </div>
    @for (
      recordId of parentIds;
      track recordId;
      let last = $last, index = $index
    ) {
      <div class="breadcrumbs__item">
        <button
          mat-button
          [disabled]="last"
          (click)="
            parentIds.splice(index);
            filterForm.patchValue({ parentId: recordId })
          "
          >{{ recordId }}</button
        >
        @if (!last) {
          <span>/</span>
        }
      </div>
    }
  </div>
}

<div class="npdc-table">
  @if (records$ | async; as records) {
    @if (records.length) {
      <mat-action-list>
        @for (record of records; track record.id) {
          <mat-list-item
            lines="3"
            (click)="filterForm.patchValue({ parentId: record.id })">
            <mat-icon matListItemIcon>
              @switch (record.type) {
                @case ('station') {
                  cell_tower
                }
                @default {
                  data_object
                }
              }
            </mat-icon>

            <span matListItemTitle>{{
              record.content['title'] || record.id
            }}</span>

            <span matListItemLine>
              {{ record.type }}
            </span>
            <span matListItemLine>
              {{ record.modified | date: 'YYYY-MM-dd HH:mm:ss' }}
            </span>

            <span matListItemMeta>
              <button
                mat-icon-button
                matTooltip="Inspect"
                (click)="$event.stopPropagation(); openRecord(record)"
                ><mat-icon>visibility</mat-icon></button
              >
            </span>
          </mat-list-item>
        }
      </mat-action-list>
    } @else {
      <div class="npdc-empty-list">There are no items to diplay.</div>
    }
  }

  <mat-paginator
    [pageSizeOptions]="[10, 20, 50]"
    (page)="loadPage($event, false)"
    showFirstLastButtons></mat-paginator>
</div>
