<div class="npdc-layout">
  <div class="npdc-layout__toolbar">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"> </app-breadcrumb>
  </div>
  <div class="npdc-layout__content">
    <h1>Not found</h1>
    <p>
      The HTTP <strong><code>404 Not Found</code></strong> response status code
      indicates that the server cannot find the requested resource. Links that
      lead to a 404 page are often called broken or dead links and can be
      subject to link rot.
    </p>
  </div>
</div>
