<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    [disableClose]="(isMobile$ | async) ? false : true"
    [mode]="(isMobile$ | async) ? 'over' : 'side'"
    [opened]="(isMobile$ | async) ? false : true">
    <div
      class="sidenav-content"
      [class.sidenav-content__side]="isMobile$ | async">
      <app-sidenav> </app-sidenav>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="npdc-content">
      <app-toolbar (toggleMenu)="sidenav.toggle()"></app-toolbar>

      <div class="npdc-content__outlet">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
