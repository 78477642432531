import { Pipe, PipeTransform } from '@angular/core';

// transforms a list of roles as to a single string
@Pipe({ name: 'roleListString' })
export class RoleListStringPipe implements PipeTransform {
  transform(roles: string[]): string {
    const results = [];
    for (const role of roles) {
      switch (role) {
        case 'author':
          results.push('Author');
          break;
        case 'editor':
          results.push('Editor');
          break;
        case 'owner':
          results.push('Owner');
          break;
        case 'originator':
          results.push('Originator');
          break;
        case 'pointOfContact':
          results.push('Point of contact');
          break;
        case 'principalInvestigator':
          results.push('Principal investigator');
          break;
        case 'processor':
          results.push('Processor');
          break;
        case 'resourceProvider':
          results.push('Resource provider');
          break;
        default:
          results.push(role[0].toUpperCase() + role.slice(1));
      }
    }

    return results.join(', ');
  }
}
