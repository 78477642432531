import * as L from 'leaflet';

export class ProjectionSelectorControl extends L.Control {
  private containerElement!: HTMLElement;
  private icons!: { [key: string]: HTMLElement };
  public buttons!: { [key: string]: HTMLElement };
  private badges!: { [key: string]: HTMLElement };

  override onAdd() {
    this.containerElement = L.DomUtil.create(
      'div',
      'leaflet-bar leaflet-control',
    );

    this.icons = {};
    this.buttons = {};
    this.badges = {};

    const initButton = (
      buttonClass: string,
      title: string,
      projection: string,
      iconClass: string,
      badgeClass: string,
    ) => {
      const buttonElement = L.DomUtil.create(
        'a',
        buttonClass,
        this.containerElement,
      );

      buttonElement.title = title;
      buttonElement.setAttribute('style', 'cursor: pointer;');
      buttonElement.setAttribute('data-projection', projection);
      buttonElement.tabIndex = 0;
      buttonElement.addEventListener('keypress', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          buttonElement.click();
        }
      });

      const iconElement = L.DomUtil.create(
        'i',
        `material-symbols-rounded ${iconClass}`,
        buttonElement,
      );
      iconElement.innerText = 'public';

      const badgeElement = L.DomUtil.create(
        'span',
        `npdc-leaflet__badge`,
        buttonElement,
      );
      badgeElement.classList.add(badgeClass);

      this.buttons[projection] = buttonElement;
      this.icons[projection] = iconElement;
      this.badges[projection] = badgeElement;
    };

    initButton(
      'npdc-leaflet__icon-button',
      'Arctic',
      'EPSG3575',
      'npdc-leaflet__icon-button__clip-top',
      'npdc-leaflet__badge__arctic',
    );

    initButton(
      'npdc-leaflet__icon-button',
      'Web Mercator',
      'EPSG3857',
      '',
      'npdc-leaflet__badge__mercator',
    );

    initButton(
      'npdc-leaflet__icon-button',
      'Antarctic',
      'EPSG3031',
      'npdc-leaflet__icon-button__clip-bottom',
      'npdc-leaflet__badge__antarctic',
    );

    return this.containerElement;
  }

  override onRemove() {
    this.containerElement?.remove();
  }

  setBadgeValues(counters: { [key: string]: number }) {
    for (const proj in this.badges) {
      if (counters[proj]) {
        this.badges[proj].style.display = 'inherit';
        this.badges[proj].innerText = counters[proj] + '';
      } else {
        this.badges[proj].style.display = 'none';
      }
    }
  }

  setActiveProjection(projection: string) {
    this.buttons[projection].classList.add('leaflet-disabled');
    this.buttons[projection].setAttribute('style', '');
  }
}
