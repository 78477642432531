import { Component } from '@angular/core';
import { Breadcrumb } from '@models';

@Component({
  selector: 'app-not-found',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.scss'],
})
export class NotFoundComponent {
  public breadcrumbs: Breadcrumb[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: '404',
      url: '/',
    },
  ];
}
