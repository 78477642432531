import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { User } from '@models';
import { AuthService, NotificationService } from '@services';
import { map, tap } from 'rxjs';

export const isLoggedInGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  return authService.user$.pipe(
    map((u: User | null) => {
      return !!u;
    }),
    tap((result: boolean) => {
      if (!result) {
        notificationService.info('You must be authorized to visit this page.');
        router.navigate(['/']);
      }
    }),
  );
};

export const isNotLoggedInGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  return authService.user$.pipe(
    map((u: User | null) => {
      return !u;
    }),
    tap((result: boolean) => {
      if (!result) {
        notificationService.info('You must be logged out to visit this page.');
        router.navigate(['']);
      }
    }),
  );
};

export const isAdminGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  return authService.user$.pipe(
    map((u: User | null) => {
      return u?.accessLevel === 'admin';
    }),
    tap((result: boolean) => {
      if (!result) {
        notificationService.info('You must be authorized to visit this page.');
        router.navigate(['']);
      }
    }),
  );
};
