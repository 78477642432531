<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Publish dataset</h2>

  <mat-dialog-content>
    <mat-label>Publish for</mat-label>
    <mat-radio-group formControlName="type" class="type-radio-group">
      <mat-radio-button [value]="DatasetType.Public"
        >Public access</mat-radio-button
      >
      <mat-radio-button [value]="DatasetType.Internal"
        >Internal use</mat-radio-button
      >
    </mat-radio-group>

    <p>This action will</p>

    <mat-list>
      @switch (form.controls['type'].value) {
        @case (DatasetType.Public) {
          <mat-list-item>Activate the reserved DOI.</mat-list-item>
          <mat-list-item
            >Make the dataset publicly visible (no login
            required).</mat-list-item
          >
        }
        @case (DatasetType.Internal) {
          <mat-list-item>Remove the reserved DOI.</mat-list-item>
          <mat-list-item
            >Grant internal users access to the dataset.</mat-list-item
          >
        }
      }
      <mat-list-item
        >Lock attached files in place (they can no longer be
        removed).</mat-list-item
      >
    </mat-list>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="submit" [disabled]="!form.valid">
      Publish
    </button>
    <button mat-button type="button" (click)="onCancel()"> Cancel </button>
  </mat-dialog-actions>
</form>
