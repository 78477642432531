import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@models';
import { UserService } from '@services';
import { Observable, of, switchMap } from 'rxjs';

// looks up the given id in user repository and replies with the user object
@Pipe({ name: 'resolveUser' })
export class ResolveUserPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(value: string): Observable<User | null> {
    if (!value) {
      return of(null);
    }

    return this.userService.getUser(value).pipe(
      switchMap((user: User | null) => {
        return of(user);
      }),
    );
  }
}
