import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@components/404/404.component';
import { AboutComponent } from '@components/about/about.component';
import { AccountPasswordComponent } from '@components/account/password/password.component';
import { UserListComponent } from '@components/admin/user/list/list.component';
import { DatasetFormComponent } from '@components/dataset/form/form.component';
import { DatasetItemComponent } from '@components/dataset/item/item.component';
import { DatasetListComponent } from '@components/dataset/list/list.component';
import { PermissionPageComponent } from '@components/permission/page/page.component';
import { isAdminGuard, isNotLoggedInGuard } from '@guards';
import { datasetResolver } from '@resolvers';

const routes: Routes = [
  { path: '', redirectTo: '/dataset', pathMatch: 'full' },
  { path: 'login', redirectTo: '/', pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [isAdminGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/admin/users',
      },
      {
        path: 'users',
        component: UserListComponent,
      },
    ],
  },
  {
    path: 'account',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: 'password',
        canActivate: [isNotLoggedInGuard],
        component: AccountPasswordComponent,
      },
    ],
  },
  {
    path: 'dataset',
    title: 'Datasets - NPDC',
    children: [
      {
        path: '',
        component: DatasetListComponent,
      },
      {
        path: ':id',
        resolve: { dataset: datasetResolver },
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            component: DatasetItemComponent,
          },
          {
            path: 'edit',
            component: DatasetFormComponent,
          },
          {
            path: 'permission',
            component: PermissionPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'about',
    title: 'About - NPDC',
    component: AboutComponent,
  },
  {
    path: '404',
    title: '404 - NPDC',
    component: NotFoundComponent,
  },
  {
    path: '**',
    title: '404 - NPDC',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
