import { AbstractControl, ValidationErrors } from '@angular/forms';

function getChecksum(orcid: string): string {
  const digits = orcid.replaceAll('-', '').split('');
  digits.pop(); // last digit is checksum

  let total = 0;
  for (const digit of digits) {
    total = (total + Number(digit)) * 2;
  }
  const remainder = total % 11;
  const result = (12 - remainder) % 11;
  if (result < 10) {
    return String(result);
  }
  return 'X'; // represents number 10
}

function hasValidChecksum(orcid: string): boolean {
  return getChecksum(orcid) == orcid[orcid.length - 1];
}

export function ORCIDValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const orcid = control.value;
  if (orcid && !hasValidChecksum(orcid)) {
    return { ORCID: { value: orcid } };
  }
  return null;
}
