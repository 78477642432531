import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export function PrefixValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const errors = {
    leadingslash: Validators.pattern('^/.*')(control),
    trailingslash: Validators.pattern('.*/$')(control),
  };

  for (const value of Object.values(errors)) {
    if (value !== null) {
      return errors;
    }
  }

  return null;
}
