<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>New dataset</h2>

  <mat-dialog-content>
    <p>
      This will create a new dataset in
      <strong>draft</strong> state.
    </p>

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>Title</mat-label>
      <input type="text" matInput formControlName="title" />
    </mat-form-field>

    <p> By default, the draft will be visible to you only, until published. </p>
    <p>
      Additional permissions may be granted to other users after creation.
    </p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="submit" [disabled]="!form.valid">
      Create
    </button>
    <button mat-button type="button" (click)="onCancel()"> Cancel </button>
  </mat-dialog-actions>
</form>
