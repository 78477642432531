<mat-form-field appearance="outline">
  <mat-label
    >{{ label }}
    @if (isRequired$ | async) {
      <span>*</span>
    }
  </mat-label>

  <mat-chip-grid
    #chipGrid
    [formControl]="chipGridFormControl"
    class="chip-grid"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="onOptionDropped($event)">
    @for (option of selectedOptions; track option) {
      <mat-chip-row
        class="chip-grid__row"
        cdkDrag
        [matTooltip]="option.id"
        [removable]="true"
        (removed)="onOptionRemoved(option)">
        <div class="chip-grid__row__text">{{ option.displayValue }}</div>
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
  </mat-chip-grid>

  <input
    placeholder="Type to search"
    [formControl]="inputFormControl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto" />

  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event.option.value)">
    @for (option of filteredOptions$ | async; track option) {
      <mat-option [value]="option">
        {{ option.id }}
      </mat-option>
    }
  </mat-autocomplete>
  @if (chipGridFormControl.hasError('required')) {
    <mat-error>This field is required.</mat-error>
  }
</mat-form-field>
