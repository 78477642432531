import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Dataset, Record, RecordListResponse } from '@models';
import { NotificationService, RecordService } from '@services';
import { BehaviorSubject, first, Observable, Subscription, tap } from 'rxjs';
import { RecordItemComponent } from '../item/item.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-record-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class RecordListComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public dataset$!: Observable<Dataset>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public records$: BehaviorSubject<Record[]>;
  public isLoading$: BehaviorSubject<boolean>;
  public filterForm: FormGroup;
  public parentIds: string[];

  private subscriptions: Subscription[];

  constructor(
    private recordService: RecordService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    this.records$ = new BehaviorSubject<Record[]>([]);
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.subscriptions = [];
    this.parentIds = [];
    this.filterForm = new FormGroup({
      datasetId: new FormControl(''),
      parentId: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.filterForm.valueChanges
        .pipe(
          tap(() => {
            this.isLoading$.next(true);
          }),
        )
        .subscribe(() => {
          if (this.filterForm.value.parentId) {
            this.parentIds.push(this.filterForm.value.parentId);
          }

          this.loadPage({ pageIndex: 0, pageSize: 10 } as PageEvent, true);
        }),
    );

    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.filterForm.patchValue({ datasetId: dataset.id });
      }),
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s?.unsubscribe();
    }
  }

  public openRecord(record: Record): void {
    this.dialog.open(RecordItemComponent, {
      data: record,
      minWidth: '360px',
    });
  }

  public loadPage(event: PageEvent, doCount: boolean): void {
    const parentId = this.filterForm.value.parentId || '';

    this.isLoading$.next(true);
    this.recordService
      .getRecords(
        this.filterForm.value.datasetId,
        parentId,
        event.pageIndex * event.pageSize,
        event.pageSize,
        doCount,
        null,
      )
      .pipe(first())
      .subscribe({
        next: (result: RecordListResponse) => {
          if (doCount && this.paginator) {
            this.paginator.firstPage();
            this.paginator.length = result.count;
          }

          this.records$.next(result.items);
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load records.');
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }
}
