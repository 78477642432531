<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>Custom geometry</h2>

  <mat-dialog-content>
    <p
      >Input one point per line, while separating latitude and longitude with a
      comma.</p
    >
    <p>Example:</p>
    <pre>
        30.0, 10.0
        40.0, 40.0
        30.0, 20.0</pre
    >
    <mat-form-field appearance="outline">
      <mat-label>Points</mat-label>
      <textarea
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        matInput
        formControlName="points">
      </textarea>
      @if (form.controls['points'].errors?.['invalidInput']) {
        <mat-error>{{
          form.controls['points'].errors?.['invalidInput']
        }}</mat-error>
      }
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      type="submit"
      [disabled]="form.pristine || !form.valid">
      Add
    </button>
    <button mat-button type="button" (click)="onCancel()"> Cancel </button>
  </mat-dialog-actions>
</form>
