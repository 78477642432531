import * as L from 'leaflet';

export class ProjectionFilterControl extends L.Control {
  private containerElement!: HTMLElement;
  public filterButtonElement!: HTMLElement;

  override onAdd() {
    this.containerElement = L.DomUtil.create(
      'div',
      'leaflet-bar leaflet-control',
    );
    this.filterButtonElement = L.DomUtil.create(
      'a',
      'npdc-leaflet__icon-button',
      this.containerElement,
    );

    this.filterButtonElement.innerHTML = "<i class='bi bi-funnel-fill'></i>";
    this.filterButtonElement.title =
      'Filter locations not entered in the selected map projection.';
    this.filterButtonElement.setAttribute('style', 'cursor: pointer;');
    this.filterButtonElement.tabIndex = 0;
    this.filterButtonElement.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.filterButtonElement.click();
      }
    });

    return this.containerElement;
  }

  override onRemove() {
    this.containerElement?.remove();
  }

  public setState(isActive: boolean) {
    if (!this.filterButtonElement) {
      return;
    }

    if (isActive) {
      this.filterButtonElement.innerHTML =
        "<i class='material-symbols-rounded'>filter_alt_off</i>";
    } else {
      this.filterButtonElement.innerHTML =
        "<i class='material-symbols-rounded'>filter_alt</i>";
    }
  }
}
