import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { Record } from '@models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@services';

@Component({
  selector: 'app-record-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class RecordItemComponent {
  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<RecordItemComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: Record,
  ) {}

  public copyToClipboard() {
    if (this.clipboard.copy(JSON.stringify(this.data))) {
      this.notificationService.success('Raw JSON copied to clipboard.');
    } else {
      this.notificationService.error('Clipboard operation failed.');
    }
  }
}
