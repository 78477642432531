<div class="npdc-layout">
  <div class="npdc-layout__toolbar">
    <app-breadcrumb [breadcrumbs]="breadcrumbs"> </app-breadcrumb>
  </div>
  <div class="npdc-layout__content">
    <h1>User management</h1>

    <form class="search-form" [formGroup]="searchForm">
      <mat-form-field appearance="outline" class="search-form__search-input">
        <mat-label>Filter users</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput type="text" formControlName="search" />
      </mat-form-field>
    </form>

    <div class="npdc-table">
      <div class="npdc-table__actions">
        <div class="npdc-table__actions__left">
          @if (selection.hasValue()) {
            <button mat-flat-button [disabled]="true">Edit</button>
            @if (
              selection.selected[0].status === 'active' ||
              selection.selected[0].status === 'pending'
            ) {
              @if (!isDirectoryUser()) {
                <button mat-flat-button (click)="pushRecovery()"
                  >Force Recovery</button
                >
              }
              <button mat-button (click)="updateStatus()">Disable</button>
            } @else {
              <button mat-flat-button (click)="updateStatus()">Enable</button>
            }
          } @else {
            <button mat-flat-button (click)="openForm()">Add</button>
          }
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>
        <ng-container matColumnDef="accessLevel">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
            >Access Level</th
          >
          <td mat-cell *matCellDef="let element">
            {{ element.accessLevel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element"> {{ element.status }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 20, 50]"
        showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
