import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Citation, Dataset } from '@models';
import { DatasetType } from '@models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dataset-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss'],
})
export class CitationComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public dataset$!: Observable<Dataset>;

  public citation$: BehaviorSubject<Citation | null>;
  public showInterimCCBY = true;
  public DatasetType = DatasetType;

  private subscriptions: Subscription[];

  constructor() {
    this.citation$ = new BehaviorSubject<Citation | null>(null);
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.citation$.next(new Citation(dataset));
      }),
    );
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }
}
