import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export function PasswordValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const errors = {
    required: Validators.required(control),
    minlength: Validators.minLength(16)(control),
    maxlength: Validators.maxLength(255)(control),
    pattern: Validators.pattern('^[!-~][ -~]+[!-~]$')(control),
  };

  for (const value of Object.values(errors)) {
    if (value !== null) {
      return errors;
    }
  }

  return null;
}
