<h2 mat-dialog-title>Log in</h2>

<mat-dialog-content>
  <form id="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="email"
        matInput
        formControlName="email"
        autocomplete="username" />
      <mat-icon matSuffix>mail</mat-icon>
      @if (loginForm.controls['email'].hasError('email')) {
        <mat-error> Invalid email address </mat-error>
      }
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        type="password"
        matInput
        formControlName="password"
        autocomplete="password" />
      <mat-icon matSuffix>password</mat-icon>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button type="submit" form="login-form">Log in</button>
  <button mat-button type="button" (click)="dialogRef.close(false)">
    Cancel
  </button>
</mat-dialog-actions>
