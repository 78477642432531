import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@models';
import { AuthService, NotificationService } from '@services';
import { Observable, catchError, switchMap, take } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.authService.user$.pipe(
      take(1),
      switchMap((user: User | null) => {
        let authRequest: HttpRequest<unknown> = req;

        if (user !== null) {
          authRequest = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${user.token}`),
          });
        }

        return next.handle(authRequest).pipe(
          catchError((err) => {
            if (err?.status === 401 && this.authService.isLoggedIn) {
              this.authService.logOut();
              this.notificationService.warning(
                'You have been logged out due to inactivity.',
              );
              this.router.navigate(['/']);
            }

            throw err;
          }),
        );
      }),
    );
  }
}
