<div class="npdc-layout">
  <div class="npdc-layout__toolbar">
    <app-breadcrumb [breadcrumbs]="breadcrumbs">
      @if (authService.isLoggedIn) {
        <button
          class="npdc-toolbar-button"
          mat-flat-button
          (click)="onNewDatasetClick()"
          >New dataset</button
        >
      }
    </app-breadcrumb>
  </div>
  <div class="npdc-layout__content">
    <h1 class="title">Welcome to the Norwegian Polar Data Centre</h1>

    <form [formGroup]="searchForm">
      <p class="npdc-search-form">
        <app-search
          [isLoading$]="isLoading$"
          formControlName="search"
          class="npdc-search-form__input">
          @if (searchForm.contains('type')) {
            <button
              mat-icon-button
              [matMenuTriggerFor]="filterMenu"
              matTooltip="Advanced search"
              ><mat-icon>tune</mat-icon></button
            >
          } @else {
            <button
              mat-icon-button
              [matMenuTriggerFor]="locationFilterMenu"
              matTooltip="Advanced search"
              ><mat-icon>tune</mat-icon></button
            >
          }
        </app-search>
      </p>
    </form>

    @if (searchForm.value.location || searchForm.value.type) {
      <div class="npdc-filter-list">
        <mat-chip-set>
          @if (searchForm.value.location; as value) {
            <mat-chip highlighted>
              <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="{ value: value }">
              </ng-container>
              <mat-icon matChipRemove (click)="applyLocationFilter(null)"
                >cancel</mat-icon
              >
            </mat-chip>
          }
          @if (searchForm.value.type; as value) {
            <mat-chip highlighted>
              <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="{ value: value }">
              </ng-container>
              <mat-icon matChipRemove (click)="applyTypeFilter(null)"
                >cancel</mat-icon
              >
            </mat-chip>
          }
        </mat-chip-set>
        <button mat-button (click)="clearFilters()">Clear filters</button>
      </div>
    }

    @for (dataset of datasets$ | async; track dataset.id; let isLast = $last) {
      <ng-container
        [ngTemplateOutlet]="datasetListItemTemplate"
        [ngTemplateOutletContext]="{ dataset: dataset, isLast: isLast }">
      </ng-container>
    }

    <mat-paginator
      [pageSizeOptions]="[defaultPageSize, 20, 50, 100, 200]"
      (page)="changePage($event)"
      showFirstLastButtons></mat-paginator>
  </div>
</div>

<mat-menu #filterMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="locationFilterMenu"
    >Location</button
  >
  <button mat-menu-item [matMenuTriggerFor]="statusFilterMenu">Status</button>
</mat-menu>

<mat-menu #locationFilterMenu="matMenu">
  @for (location of ['antarctica', 'arcticOcean', 'svalbard']; track location) {
    <button mat-menu-item (click)="applyLocationFilter(location)">
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ value: location }">
      </ng-container>
    </button>
  }
</mat-menu>

<mat-menu #statusFilterMenu="matMenu">
  @for (type of ['public', 'internal', 'draft']; track type) {
    <button mat-menu-item (click)="applyTypeFilter(type)">
      <ng-container
        [ngTemplateOutlet]="optionTemplate"
        [ngTemplateOutletContext]="{ value: type }">
      </ng-container>
    </button>
  }
</mat-menu>

<ng-template #optionTemplate let-value="value">
  @switch (value) {
    @case ('antarctica') {
      Antarctica
    }
    @case ('arcticOcean') {
      Arctic Ocean
    }
    @case ('svalbard') {
      Svalbard
    }
    @case ('draft') {
      Draft
    }
    @case ('internal') {
      Internal
    }
    @case ('public') {
      Public
    }
    @default {
      {{ value }}
    }
  }
</ng-template>

<ng-template #datasetListItemTemplate let-dataset="dataset" let-isLast="isLast">
  <div>
    <h2 class="npdc-title">
      <a routerLink="/dataset/{{ dataset.id }}">{{ dataset.content.title }}</a>
    </h2>

    @if (getCitation(dataset); as citation) {
      @if (citation.published) {
        <p>
          {{ citation.authors }} ({{ citation.publicationYear }}).
          {{ citation.title }} [Data set]. {{ citation.publisher }}.
        </p>
        @if (dataset.type === DatasetType.Public) {
          <p>
            <strong class="doi-link">{{ citation.URL }}</strong>
          </p>
        }
      }
    }

    @if (dataset.type !== DatasetType.Public) {
      <p>
        <mat-chip-set matListItemLine>
          @switch (dataset.type) {
            @case (DatasetType.Draft) {
              <mat-chip>Draft</mat-chip>
            }
            @case (DatasetType.Internal) {
              <mat-chip>Internal</mat-chip>
            }
          }
        </mat-chip-set>
      </p>
    }

    @if (!isLast) {
      <mat-divider></mat-divider>
    }
  </div>
</ng-template>
