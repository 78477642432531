import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  Attachment,
  AttachmentListResponse,
  PrefixListResponse,
  NetworkState,
  FormState,
  Dataset,
  FileUploadResponse,
  DatasetType,
  PrefixInfo,
} from '@models';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { AttachmentService, NotificationService } from '@services';
import { AttachmentFormComponent } from '../form/form.component';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  debounce,
  distinctUntilChanged,
  from,
  map,
  mergeAll,
  pairwise,
  skip,
  startWith,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AttachmentItemComponent } from '../item/item.component';
import { MatSelectionListChange } from '@angular/material/list';
import { FormControl, FormGroup } from '@angular/forms';
import { AttachmentUploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { AttachmentSearchDialogComponent } from '../search-dialog/search-dialog.component';
import { environment } from '@environment';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AttachmentListComponent implements OnInit, OnDestroy {
  @Input()
  public dataset$!: Observable<Dataset>;

  @Input()
  public canDelete = true;

  @Input()
  public isReadOnly = true;

  @Input()
  formState!: BehaviorSubject<FormState> | null;

  @Input()
  networkState!: BehaviorSubject<NetworkState> | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;

  public DatasetType = DatasetType;
  public form: FormGroup;
  public fileHostUrl: string;
  public prefixBreadCrumb: Map<string, string>;
  public selection: SelectionModel<Attachment> | null;

  public prefixTree$: BehaviorSubject<Map<string, PrefixInfo> | null>;
  public attachments$: BehaviorSubject<Attachment[]>;
  public isLoading$: BehaviorSubject<boolean>;
  public isDirectoryView$: BehaviorSubject<boolean>;
  public isMobile$: Observable<boolean>;

  private subscriptions: Subscription[];

  constructor(
    private attachmentService: AttachmentService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
  ) {
    this.fileHostUrl = environment.kinkoHost;
    this.prefixBreadCrumb = new Map<string, string>();
    this.selection = null;
    this.form = new FormGroup({
      search: new FormControl(''),
      from: new FormControl(null),
      until: new FormControl(null),
      prefix: new FormControl(''),
    });

    this.prefixTree$ = new BehaviorSubject<Map<string, PrefixInfo> | null>(
      null,
    );
    this.attachments$ = new BehaviorSubject<Attachment[]>([]);
    this.isLoading$ = new BehaviorSubject<boolean>(true);
    this.isDirectoryView$ = new BehaviorSubject<boolean>(true);
    this.isMobile$ = breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map((result: BreakpointState) => result.matches));

    this.subscriptions = [];
  }

  ngOnInit() {
    if (!this.isReadOnly) {
      this.selection = new SelectionModel<Attachment>(this.canDelete, []);
      this.isDirectoryView$.next(false);
    }

    this.subscriptions.push(
      this.isDirectoryView$.pipe(skip(1)).subscribe((value: boolean) => {
        if (value) {
          this.form.reset({ prefix: '/' });
        } else {
          this.form.patchValue({ prefix: '' });
        }
      }),
    );

    this.subscriptions.push(
      this.prefixTree$.pipe(skip(1)).subscribe(() => {
        this.form.reset({ prefix: this.isReadOnly ? '/' : '' });
      }),
    );

    this.subscriptions.push(
      this.dataset$.subscribe((dataset: Dataset) => {
        this.loadPrefixes(dataset.id);
      }),
    );

    this.subscriptions.push(
      this.form.valueChanges
        .pipe(
          startWith(this.form.value),
          distinctUntilChanged(),
          tap(() => {
            this.isLoading$.next(true);
          }),
          pairwise(),
          debounce(([previousValues, currentValues]) => {
            // add a timer if the search string is changing
            if (
              currentValues.search &&
              previousValues.search !== currentValues.search
            ) {
              return timer(500);
            }

            return timer(0);
          }),
          withLatestFrom(this.dataset$, this.isDirectoryView$),
        )
        .subscribe(
          ([[previousValues, currentValues], dataset, isDirectoryView]) => {
            if (
              isDirectoryView &&
              previousValues.prefix === currentValues.prefix
            ) {
              // this will trigger a new form change
              this.isDirectoryView$.next(false);
            } else {
              this.prefixCrumbs();
              this.reloadData(dataset.id);
            }
          },
        ),
    );
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s?.unsubscribe();
    }
  }

  public loadPage(event: PageEvent, datasetId: string, doCount: boolean) {
    const searchFormValues = this.form.enabled ? this.form.value : {};

    // Load attachment list for the given dataset
    this.attachmentService
      .getAttachments(
        datasetId,
        event.pageIndex * event.pageSize,
        event.pageSize,
        doCount,
        {
          q: searchFormValues.search,
          from: searchFormValues.from,
          until: searchFormValues.until,
          prefix: searchFormValues.prefix,
        },
        [{ column: 'created', direction: 'desc' }],
      )
      .subscribe({
        next: (result: AttachmentListResponse) => {
          if (doCount && this.paginator) {
            this.paginator.length = result.count;
          }

          this.attachments$.next(result.items);
          this.formState?.next(
            result.items.length ? FormState.Valid : FormState.Invalid,
          );
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load files.');
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }

  public prefixCrumbs() {
    // Empty the current prefix bread crumbs
    this.prefixBreadCrumb = new Map<string, string>();

    // If the form contains a prefix that is not null or empty string
    if (this.form.value.prefix !== null && this.form.value.prefix !== '') {
      let segments = this.form.value.prefix?.split('/');
      segments = segments.slice(1, segments.length - 1);

      let path: string = '/';
      for (const seg of segments) {
        path += seg + '/';
        this.prefixBreadCrumb.set(path, seg);
      }
    }
  }

  public openAdvancedSearch() {
    const dialogRef = this.dialog.open(AttachmentSearchDialogComponent, {
      data: this.form.value,
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((updatedFormData: object | null) => {
      if (updatedFormData == null) {
        return;
      }

      this.form.patchValue(updatedFormData);
    });
  }

  public openItem(attachment: Attachment, isDatasetPublic: boolean) {
    this.dialog.open(AttachmentItemComponent, {
      data: {
        attachment: attachment,
        isDatasetPublic: isDatasetPublic,
      },
      minWidth: '360px',
    });
  }

  public openForm(attachment: Attachment) {
    const dialogRef = this.dialog.open(AttachmentFormComponent, {
      data: attachment,
      minWidth: '360px',
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .subscribe((updatedAttachment: Attachment | null) => {
        if (!updatedAttachment) {
          return;
        }

        const requestBody = {
          filename: updatedAttachment.filename,
          prefix: updatedAttachment.prefix,
          title: updatedAttachment.title,
          description: updatedAttachment.description,
          released: updatedAttachment.released,
          licence: updatedAttachment.licence,
        } as Attachment;

        this.networkState?.next(NetworkState.Pending);

        this.attachmentService
          .updateAttachment(attachment.datasetId, attachment.id, requestBody)
          .subscribe({
            next: () => {
              this.networkState?.next(NetworkState.Success);
              this.reloadData(attachment.datasetId);
            },
            error: (err) => {
              console.error(err);
              this.networkState?.next(NetworkState.Error);
              this.notificationService.error('Failed to update file metadata.');
            },
          });
      });
  }

  public onDelete() {
    const selectedAttachments = this.selection?.selected || [];

    this.networkState?.next(NetworkState.Pending);

    from(selectedAttachments)
      .pipe(
        map((attachment: Attachment) => {
          return this.attachmentService.deleteAttachment(
            attachment.datasetId,
            attachment.id,
          );
        }),
        mergeAll(),
      )
      .subscribe({
        next: () => {
          this.networkState?.next(NetworkState.Success);
        },
        error: (err) => {
          console.error(err);
          this.networkState?.next(NetworkState.Error);
          this.notificationService.error(
            'Failed to delete one or multiple files.',
          );
        },
      })
      .add(() => {
        if (selectedAttachments.length) {
          this.reloadData(selectedAttachments[0].datasetId);
        }
      });
  }

  public onFileInputChange(event: Event, datasetId: string) {
    const element = event.target as HTMLInputElement;
    const files: FileList | null = element.files;
    if (!files) {
      return;
    }

    this.networkState?.next(NetworkState.Pending);

    const responses$ = new Subject<FileUploadResponse[]>();
    const dialogRef = this.dialog.open(AttachmentUploadDialogComponent, {
      data: {
        uploadResponses: responses$,
      },
      minWidth: '360px',
      disableClose: true,
    });

    this.attachmentService.createAttachment(datasetId, files).subscribe({
      next: (responses: FileUploadResponse[]) => {
        element.value = '';

        responses$.next(responses);

        this.networkState?.next(NetworkState.Success);
        this.reloadData(datasetId);
      },
      error: (err) => {
        console.error(err);

        element.value = '';

        dialogRef.close();

        this.networkState?.next(NetworkState.Error);
        this.notificationService.error('Failed to upload files.');
      },
    });
  }

  public onListSelectionChange(event: MatSelectionListChange) {
    for (const option of event.options) {
      if (option.selected) {
        this.selection?.select(option.value);
      } else {
        this.selection?.deselect(option.value);
      }
    }
  }

  public isPublicAttachment(attachment: Attachment): boolean {
    if (attachment?.released === '0001-01-01T00:00:00Z') {
      return true;
    }

    return Date.parse(attachment.released) < Date.now();
  }

  public get queryParams(): string {
    const queryParams: string[] = [];
    let isSearch: boolean = false;

    const keys: string[] = Object.keys(this.form.value);
    for (let key of keys) {
      const value = this.form.value[key];
      if (value === '' || value === undefined || value === null) {
        continue;
      }

      if (key === 'search') {
        key = 'q';
        isSearch = true;
      }

      if (key === 'prefix' && !isSearch) {
        queryParams.push(`recursive=true`);
      }

      queryParams.push(`${key}=${value}`);
    }

    if (queryParams.length > 0) {
      return '&' + queryParams.join('&');
    }
    return '';
  }

  private reloadData(datasetId: string) {
    this.selection?.clear();

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.loadPage(
      {
        pageIndex: 0,
        pageSize: 10,
      } as PageEvent,
      datasetId,
      true,
    );
  }

  private loadPrefixes(datasetId: string) {
    const prefixTree = new Map<string, PrefixInfo>();
    this.attachmentService
      .getPrefixes(datasetId, 0, 255)
      .subscribe({
        next: (result: PrefixListResponse) => {
          // Generate a full directory tree from the retrieved prefixList.
          for (const dir of result.items) {
            let path = '';
            let prev = '';
            let segments = dir.prefix.split('/');
            if (segments.length > 1) {
              segments = segments.slice(0, segments.length - 1);
            }
            for (const seg of segments) {
              prev = path;
              path += seg + '/';

              if (!prefixTree.has(path)) {
                prefixTree.set(path, {
                  dir: new Map(),
                  prev: prev,
                  count: dir.fileCount,
                  size: dir.byteSize,
                } as PrefixInfo);
              } else {
                const currentPath = prefixTree.get(path)!;

                prefixTree.set(path, {
                  dir: currentPath.dir,
                  prev: prev,
                  count: currentPath.count + dir.fileCount,
                  size: currentPath.size + dir.byteSize,
                } as PrefixInfo);
              }

              if (prev !== '') {
                const previousPath = prefixTree.get(prev)!;

                prefixTree.set(prev, {
                  dir: previousPath.dir.set(path, seg),
                  prev: previousPath.prev,
                  count: previousPath.count,
                  size: previousPath.size,
                } as PrefixInfo);
              }
            }
          }
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load file prefixes.');
          }
        },
      })
      .add(() => {
        this.prefixTree$.next(prefixTree);
      });
  }

  public truncate(text: string) {
    if (text.length > 10) {
      return `${text.slice(0, 10)}...`;
    }

    return text;
  }
}
