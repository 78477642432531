import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ThemeStyle {
  Light,
  Dark,
}

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public theme$: Observable<ThemeStyle>;

  private themeSubject: BehaviorSubject<ThemeStyle>;
  private prefersDarkMediaQuery: MediaQueryList | null;

  constructor() {
    this.prefersDarkMediaQuery = null;

    if (window.matchMedia) {
      this.prefersDarkMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)',
      );

      this.prefersDarkMediaQuery.addEventListener('change', (event) => {
        if (event.matches) {
          this.themeSubject.next(ThemeStyle.Dark);
        } else {
          this.themeSubject.next(ThemeStyle.Light);
        }
      });
    }

    this.themeSubject = new BehaviorSubject<ThemeStyle>(
      this.prefersDarkMediaQuery?.matches ? ThemeStyle.Dark : ThemeStyle.Light,
    );
    this.theme$ = this.themeSubject.asObservable();
  }
}
