@if (!isEdit) {
  <h2 mat-dialog-title>Add timeframe</h2>
} @else {
  <h2 mat-dialog-title>Edit timeframe</h2>
}

<mat-dialog-content>
  <form id="timeframe-form" [formGroup]="timeframeForm" (ngSubmit)="onSubmit()">
    <div class="toggle">
      <mat-slide-toggle formControlName="isOngoing"
        >Is ongoing</mat-slide-toggle
      >
    </div>

    @if (timeframeForm.controls['isOngoing'].value) {
      <mat-form-field appearance="outline">
        <mat-label>Start date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="startDate" />
        <mat-hint>YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    } @else {
      <mat-form-field appearance="outline">
        <mat-label>Timeframe</mat-label>
        <mat-date-range-input
          [formGroup]="timeframeForm"
          [rangePicker]="timeframePicker"
          [comparisonStart]="timeframeForm.value.startDate"
          [comparisonEnd]="timeframeForm.value.endDate">
          <input
            matStartDate
            placeholder="Start date"
            formControlName="startDate" />
          <input matEndDate placeholder="End date" formControlName="endDate" />
        </mat-date-range-input>
        <mat-hint>YYYY-MM-DD – YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="timeframePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #timeframePicker></mat-date-range-picker>
      </mat-form-field>
    }
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="timeframe-form"
    [disabled]="timeframeForm.pristine || !timeframeForm.valid">
    Save
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
