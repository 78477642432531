<div class="npdc-table">
  <div class="npdc-table__actions">
    <div class="npdc-table__actions__left">
      @if (!selection.hasValue()) {
        <button mat-flat-button (click)="openForm()">Add</button>
      } @else {
        @if (selection.selected.length === 1) {
          <button mat-flat-button (click)="openForm(selection.selected[0])"
            >Edit</button
          >
        }
        <button mat-button (click)="onDelete()">Delete</button>
      }
    </div>
  </div>

  <div class="npdc-table__table">
    @if (!dataSource.data.length) {
      <div class="npdc-empty-list"> There are no items to diplay. </div>
    } @else {
      <mat-selection-list
        [multiple]="selection.isMultipleSelection()"
        (selectionChange)="onListSelectionChange($event)">
        @for (element of dataSource.data; track element; let isLast = $last) {
          <mat-list-option
            togglePosition="before"
            class="list-item"
            [value]="element"
            [selected]="selection.isSelected(element)">
            <span matListItemTitle><b>Start</b>: {{ element.startDate }}</span>
            @if (element.endDate) {
              <span matListItemLine><b>End</b>: {{ element.endDate }}</span>
            }
          </mat-list-option>
        }
      </mat-selection-list>
    }
  </div>
</div>
