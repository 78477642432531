@if (dataset$ | async; as dataset) {
  <div class="npdc-layout">
    <div class="npdc-layout__toolbar">
      <app-breadcrumb [breadcrumbs]="breadcrumbs"> </app-breadcrumb>
    </div>
    <div class="npdc-layout__content">
      <h1 class="npdc-title">Permissions - {{ dataset.content.title }}</h1>
      <app-permission-table [datasetId]="dataset.id"> </app-permission-table>
      <p style="margin-top: 1em">
        Manage users access to the dataset and which actions they can perform on
        it. The actions a user can perform are determined by their assigned
        role.
      </p>
      <table class="npdc-basic-table">
        <thead>
          <tr>
            <th>Role</th>
            <th>Permissions</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Reader</td>
            <td>
              <ul>
                <li>Read</li>
              </ul>
            </td>
            <td>
              This user can access the dataset description and download the
              associated files before the dataset has been published. This role
              can be used to grant access to people who might need to review the
              dataset description and files prior to final publication.
            </td>
          </tr>
          <tr>
            <td>Contributor</td>
            <td>
              <ul>
                <li>Read</li>
                <li>Update</li>
              </ul>
            </td>
            <td>
              In addition to full read access this user can make changes to the
              dataset metadata and upload new data files to the dataset.
            </td>
          </tr>
          <tr>
            <td>Owner</td>
            <td>
              <ul>
                <li>Read</li>
                <li>Update</li>
                <li>Delete</li>
              </ul>
            </td>
            <td>
              This user has access to all dataset functionality, this means they
              can read, update and delete the dataset and any of its files as
              long as the dataset hasn't been published. Users with this role
              can grant dataset access to other registered users. This role is
              automatically assigned to the dataset creator.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}
