<mat-toolbar class="npdc-toolbar">
  <button
    mat-icon-button
    class="sidenav-button"
    matTooltip="Toggle navigation drawer"
    (click)="toggleMenu.emit(null)">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="home-link">
    <a mat-button href="/">Norwegian Polar Data Centre</a>
  </div>

  @if (currentRoute !== '/dataset' && (isMobile$ | async) === false) {
    <app-dataset-search-bar> </app-dataset-search-bar>
  }

  <span class="spacer"></span>

  @if (currentUser !== null) {
    <button
      mat-icon-button
      matTooltip="{{ currentUser.email }}"
      (click)="isAccountMenuOpen = true"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <mat-icon>account_circle</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="isAccountMenuOpen"
      (detach)="isAccountMenuOpen = false"
      (backdropClick)="isAccountMenuOpen = false">
      <mat-card
        class="npdc-overlay"
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true">
        <mat-card-header>
          <mat-card-title>Hello,</mat-card-title>
          <mat-card-subtitle>{{ currentUser.email }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content> </mat-card-content>
        <mat-card-footer>
          <mat-chip-set>
            <mat-chip highlighted>
              {{ currentUser.accessLevel }}
            </mat-chip>
          </mat-chip-set>
        </mat-card-footer>
        <mat-card-actions align="end">
          <a
            mat-button
            (click)="isAccountMenuOpen = false; logOut()"
            routerLink="/"
            >Log out</a
          >
        </mat-card-actions>
      </mat-card>
    </ng-template>
  } @else {
    <button mat-flat-button (click)="openLoginDialog()"> Log in </button>
  }
</mat-toolbar>
