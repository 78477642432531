@if (dataset$ | async; as dataset) {
  <div class="npdc-layout">
    <div class="npdc-layout__toolbar">
      <app-breadcrumb [breadcrumbs]="breadcrumbs">
        @if (networkState | async; as state) {
          <div class="state-container">
            @switch (state) {
              @case (NetworkStateType.Pending) {
                <div class="state-container__state">
                  <mat-progress-spinner
                    [diameter]="24"
                    class="state-container__state__icon"
                    mode="indeterminate">
                  </mat-progress-spinner>
                  <span class="state-container__state__text">Saving...</span>
                </div>
              }
              @case (NetworkStateType.Success) {
                <div class="state-container__state">
                  <mat-icon class="state-container__state__icon">
                    check_circle
                  </mat-icon>
                  <span class="state-container__state__text">Saved</span>
                </div>
              }
              @case (NetworkStateType.Error) {
                <div class="state-container__state">
                  <mat-icon
                    class="state-container__state__icon npdc-error-icon">
                    error
                  </mat-icon>
                  <span class="state-container__state__text"
                    >Failed to save</span
                  >
                </div>
              }
            }
          </div>
        }
        <button
          class="npdc-toolbar-button"
          mat-flat-button
          [routerLink]="['/dataset', dataset.id]"
          >Close</button
        >
      </app-breadcrumb>
    </div>
    <div class="npdc-layout__content">
      <h1 class="npdc-title">Edit - {{ dataset.content.title }}</h1>
      <mat-tab-group
        #tabGroup
        animationDuration="0ms"
        disablePagination="true"
        dynamicHeight>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: combineStates(
                  formState.title | async,
                  formState.summary | async,
                  formState.keywords | async
                ),
                default: 'info',
              }">
            </ng-container>
            General
          </ng-template>
          <app-dataset-form-metadata
            [dataset]="dataset"
            [networkState]="networkState"
            [titleFormState]="formState.title"
            [summaryFormState]="formState.summary"
            [keywordsFormState]="formState.keywords">
          </app-dataset-form-metadata>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.geojson | async),
                default: 'public',
              }">
            </ng-container>
            Geographical Coverage
          </ng-template>
          <ng-template matTabContent>
            <app-dataset-form-geocoverage
              [dataset]="dataset"
              [networkState]="networkState"
              [formState]="formState.geojson">
            </app-dataset-form-geocoverage>
          </ng-template>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.timeframes | async),
                default: 'schedule',
              }">
            </ng-container>
            Timeframes
          </ng-template>
          <app-dataset-form-timeframe-list
            [dataset]="dataset"
            [networkState]="networkState"
            [formState]="formState.timeframes">
          </app-dataset-form-timeframe-list>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.contributors | async),
                default: 'person',
              }">
            </ng-container>
            Contributors
          </ng-template>
          <app-dataset-form-person-list
            [dataset]="dataset"
            [networkState]="networkState"
            [formState]="formState.contributors">
          </app-dataset-form-person-list>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.organisations | async),
                default: 'business',
              }">
            </ng-container>
            Organisations
          </ng-template>
          <app-dataset-form-organisation-list
            [dataset]="dataset"
            [networkState]="networkState"
            [formState]="formState.organisations">
          </app-dataset-form-organisation-list>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.links | async),
                default: 'link',
              }">
            </ng-container>
            Links
          </ng-template>
          <app-dataset-form-link-list
            [dataset]="dataset"
            [networkState]="networkState"
            [formState]="formState.links">
          </app-dataset-form-link-list>
        </mat-tab>
        <mat-tab bodyClass="npdc-tab">
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.harvesters | async),
                default: 'present_to_all',
              }">
            </ng-container>
            Harvesters
          </ng-template>
          <app-dataset-form-harvesters
            [dataset]="dataset"
            [networkState]="networkState"
            [formState]="formState.harvesters">
          </app-dataset-form-harvesters>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <ng-container
              [ngTemplateOutlet]="tabIcon"
              [ngTemplateOutletContext]="{
                type: (formState.attachments | async),
                default: 'attach_file',
              }">
            </ng-container>
            Files
          </ng-template>
          <app-attachment-list
            [isReadOnly]="false"
            [dataset$]="dataset$"
            [canDelete]="
              dataset.type === DatasetType.Draft || (isAdmin$ | async) === true
            "
            [networkState]="networkState"
            [formState]="formState.attachments">
          </app-attachment-list>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
}

<ng-template #tabIcon let-type="type" let-default="default">
  @switch (type) {
    @case (FormStateType.Invalid) {
      <mat-icon class="npdc-tab__icon npdc-error-icon">error</mat-icon>
    }
    @default {
      <mat-icon class="npdc-tab__icon">{{ default }}</mat-icon>
    }
  }
</ng-template>
