@if (!isEdit) {
  <h2 mat-dialog-title>Add permission</h2>
} @else {
  <h2 mat-dialog-title>Edit permission</h2>
}

<mat-dialog-content>
  <form
    id="permission-form"
    [formGroup]="permissionForm"
    (ngSubmit)="onSubmit()">
    @if (!isEdit) {
      <app-form-control-autocomplete
        label="User email"
        [provider]="autocompleteProvider"
        formControlName="user" />
    }

    <mat-form-field appearance="outline">
      <mat-label>Access role</mat-label>
      <mat-select formControlName="role">
        <mat-option value="Reader">Reader</mat-option>
        <mat-option value="Contributor">Contributor</mat-option>
        <mat-option value="Owner">Owner</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="permission-form"
    [disabled]="permissionForm.pristine || !permissionForm.valid">
    Save
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
