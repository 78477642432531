import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { DatasetService, NotificationService } from '@services';
import { Dataset } from '../models/dataset.model';
import { catchError, of } from 'rxjs';

export const datasetResolver: ResolveFn<Dataset | null> = (
  route: ActivatedRouteSnapshot,
) => {
  const datasetService = inject(DatasetService);
  const notificationService = inject(NotificationService);
  const router = inject(Router);

  const datasetId = route.paramMap.get('id');
  if (!datasetId) {
    return of(null);
  }

  return datasetService.getDataset(datasetId).pipe(
    catchError((err) => {
      console.error(err);

      switch (err?.status) {
        case 401:
          break;
        default:
          router.navigate(['/404'], {
            skipLocationChange: true,
          });
          notificationService.error('Failed to load the dataset.');
          break;
      }

      return of(null);
    }),
  );
};
