import { Dataset, DatasetType, Person } from './dataset.model';

const MaxAPAPeople = 7;
const DOIBaseURL = 'https://doi.org/';

function getAPAStyleAuthorName(person: Person) {
  let name = person.lastName + ', ';
  for (const firstName of person.firstName.split(' ')) {
    if (firstName != '') {
      name += firstName[0] + '.';
    }
  }
  return name;
}

export class Citation {
  public readonly dataset: Dataset;

  constructor(dataset: Dataset) {
    this.dataset = dataset;
  }

  get authorListFromOrganisations(): string[] {
    if (!this.dataset.content.organisations) {
      return [];
    }

    return this.dataset.content.organisations
      .filter((o) => o.roles.includes('author'))
      .map((o) => o.name);
  }

  get authorListFromPeople(): string[] {
    if (!this.dataset.content.people) {
      return [];
    }

    return this.dataset.content.people
      .filter((p) => p.roles.includes('author'))
      .map((p) => getAPAStyleAuthorName(p));
  }

  get authors(): string {
    let authors = this.authorListFromPeople;
    if (authors.length == 0) {
      authors = this.authorListFromOrganisations;
    }

    let listString = '';
    let separator = '';

    const l = authors.length;
    for (let i = 0; i < l; i++) {
      const author = authors[i];
      if (i < MaxAPAPeople - 1) {
        listString += separator + author;
        separator = ', ';
      } else if (i == l - 1) {
        listString += ', … ' + author;
      }
    }

    return listString.trim();
  }

  get published(): boolean {
    return this.dataset.type !== DatasetType.Draft;
  }

  get publicationYear(): string {
    return this.dataset.published.substring(0, 4);
  }

  get publisher(): string {
    return 'Norwegian Polar Institute';
  }

  get quotable(): boolean {
    return this.published && !!this.dataset.doi;
  }

  get title(): string {
    return this.dataset.content.title;
  }

  get URL(): string {
    return DOIBaseURL + this.dataset.doi;
  }
}
