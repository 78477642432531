import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Citation, Dataset, DatasetListResponse } from '@models';
import { DatasetService, NotificationService } from '@services';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  debounce,
  filter,
  first,
  tap,
  timer,
} from 'rxjs';

@Component({
  selector: 'app-dataset-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class DatasetSearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('toolbarInput', { static: false })
  toolbarInputRef!: ElementRef<HTMLElement>;

  public searchFormControl: FormControl;
  public isLoading$: BehaviorSubject<boolean>;
  public datasets: Dataset[];
  public isOverlayOpen$: Subject<boolean>;

  private subscriptions: Subscription[];

  constructor(
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.subscriptions = [];
    this.datasets = [];

    this.searchFormControl = new FormControl();
    this.isLoading$ = new BehaviorSubject<boolean>(false);
    this.isOverlayOpen$ = new Subject();
  }

  ngOnInit() {
    const searchInputSubscription = this.searchFormControl.valueChanges
      .pipe(
        tap((searchTerm: string) => {
          if (!searchTerm?.length) {
            this.datasets = [];
            this.closeOverlay();
          }
        }),
        filter((searchTerm: string) => {
          return searchTerm?.length > 0;
        }),
        tap(() => {
          this.isLoading$.next(true);
        }),
        debounce(() => timer(500)),
      )
      .subscribe((searchTerm: string) => {
        this.searchDatasets(searchTerm);
      });

    this.subscriptions.push(searchInputSubscription);
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public openOverlay() {
    this.isOverlayOpen$.next(true);
  }

  public closeOverlay() {
    this.isOverlayOpen$.next(false);
    this.searchFormControl.setValue(this.searchFormControl.value, {
      emitEvent: false,
    });
  }

  public getCitation(dataset: Dataset): Citation {
    return new Citation(dataset);
  }

  private searchDatasets(term: string) {
    this.datasetService
      .getDatasets(
        0,
        5,
        false,
        {
          search: term,
        },
        [{ column: 'modified', direction: 'desc' }],
      )
      .pipe(first())
      .subscribe({
        next: (result: DatasetListResponse) => {
          this.datasets = result.items;

          if (this.datasets.length) {
            this.openOverlay();
          } else {
            this.closeOverlay();
          }
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load datasets.');
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }
}
