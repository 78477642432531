<h2 mat-dialog-title>Add user</h2>

<mat-dialog-content>
  <form id="user-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        type="email"
        matInput
        formControlName="email"
        autocomplete="username" />
      <mat-icon matSuffix>mail</mat-icon>
      @if (form.controls['email'].hasError('email')) {
        <mat-error>Invalid email address</mat-error>
      } @else if (form.controls['email'].hasError('domain')) {
        <mat-error>The domain '&#64;npolar.no' is not allowed</mat-error>
      }
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="user-form"
    [disabled]="form.pristine || !form.valid">
    Add
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
