<div class="breadcrumb-container">
  <div class="breadcrumb-container__left">
    @for (b of breadcrumbs; track b; let last = $last) {
      @if (!last) {
        <a mat-button routerLink="{{ b.url }}">{{ truncate(b.title) }}</a>
        /
      } @else {
        <button mat-button disabled>{{ truncate(b.title) }}</button>
      }
    }
  </div>

  <div class="breadcrumb-container__right">
    <ng-content></ng-content>
  </div>
</div>
