import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  User = 'npdc.user',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public get<T>(key: LocalStorageKey): T | null {
    const storedJson = localStorage.getItem(key);
    if (storedJson != null) {
      try {
        return JSON.parse(storedJson);
      } catch {
        return null;
      }
    }

    return null;
  }

  public set<T>(key: LocalStorageKey, item: T | null) {
    if (item === null) {
      localStorage.removeItem(key);
      return;
    }

    const stringifiedJson = JSON.stringify(item);
    localStorage.setItem(key, stringifiedJson);
  }

  public remove(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }
}
