import * as L from 'leaflet';

export class ErrorMessageControl extends L.Control {
  private errorMessageElement!: HTMLElement;

  override onAdd() {
    this.errorMessageElement = L.DomUtil.create(
      'div',
      'npdc-leaflet__error-message leaflet-bar',
    );

    this.errorMessageElement.style.display = 'none';
    return this.errorMessageElement;
  }

  override onRemove() {
    this.errorMessageElement.remove();
  }

  public show(message: string) {
    this.errorMessageElement.innerHTML = message;
    this.errorMessageElement.style.display = 'block';
  }

  public hide() {
    this.errorMessageElement.style.display = 'none';
  }
}
