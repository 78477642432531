import { Component, Input } from '@angular/core';
import { Breadcrumb } from '@models';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input()
  public breadcrumbs: Breadcrumb[] = [];

  public truncate(text: string) {
    if (text.length > 15) {
      return `${text.slice(0, 15)}...`;
    }

    return text;
  }
}
