@if (!isEdit) {
  <h2 mat-dialog-title>Add organisation</h2>
} @else {
  <h2 mat-dialog-title>Edit organisation</h2>
}

<mat-dialog-content>
  <form
    id="organisation-form"
    [formGroup]="organisationForm"
    (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Roles</mat-label>
      <mat-select matInput formControlName="roles" multiple>
        <mat-option value="author">Author</mat-option>
        <mat-option value="originator">Originator</mat-option>
        <mat-option value="owner">Owner</mat-option>
        <mat-option value="pointOfContact">Point of contact</mat-option>
        <mat-option value="principalInvestigator">
          Principal investigator
        </mat-option>
        <mat-option value="processor">Processor</mat-option>
        <mat-option value="resourceProvider">Resource provider</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>URL</mat-label>
      <mat-hint
        >URLs must include the protocol (ex: https://example.com)</mat-hint
      >
      <input matInput formControlName="url" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-flat-button
    type="submit"
    form="organisation-form"
    [disabled]="organisationForm.pristine || !organisationForm.valid">
    Save
  </button>
  <button mat-button type="button" (click)="onCancel()"> Cancel </button>
</mat-dialog-actions>
