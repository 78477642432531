<h2 mat-dialog-title>{{ data.content['title'] || data.id }}</h2>

<mat-dialog-content>
  @switch (data.type) {
    @case ('station') {
      <ng-container [ngTemplateOutlet]="stationContent"> </ng-container>
    }
    @default {
      <ng-container [ngTemplateOutlet]="rawContent"> </ng-container>
    }
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button type="button" (click)="copyToClipboard()"
    >Copy JSON to clipboard</button
  >
  <button mat-button type="button" (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>

<ng-template #stationContent>
  <h3>Description</h3>
  <p style="white-space: pre-line">
    {{ data.content['description'] }}
  </p>

  <h3>Coordinates</h3>
  <p>
    {{ data.content['coordinates']?.join(', ') }}
  </p>

  <h3>Values</h3>
  <mat-list>
    @for (element of data.content['values']; track element['key']) {
      <mat-list-item lines="3">
        <span matListItemTitle>{{ element['key'] }}</span>
        <span matListItemLine>{{ element['value'] }} </span>
        <span matListItemLine>Unit: {{ element['unit'] }}</span>
      </mat-list-item>
    }
  </mat-list>

  <h3>Other</h3>
  <mat-list>
    <!--async resolution of user email here scrolls the dialog to the bottom for some reason-->
    <mat-list-item>
      <div matListItemTitle>Created</div>
      <div matListItemLine>
        {{ data.created | date: 'YYYY-MM-dd HH:mm:ss' }} by {{ data.createdBy }}
      </div>
    </mat-list-item>
    <mat-list-item>
      <div matListItemTitle>Modified</div>
      <div matListItemLine>
        {{ data.modified | date: 'YYYY-MM-dd HH:mm:ss' }} by
        {{ data.modifiedBy }}
      </div>
    </mat-list-item>
  </mat-list>
</ng-template>

<ng-template #rawContent>
  <code>{{ data | json }}</code>
</ng-template>
