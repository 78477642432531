import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router } from '@angular/router';
import { NotificationService } from '@services';
import { Observable, Subscription, filter, map, withLatestFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;

  public isMobile$: Observable<boolean>;

  private routerEventsSubscription: Subscription;

  constructor(
    router: Router,
    notificationService: NotificationService,
    breakpointObserver: BreakpointObserver,
    iconRegistry: MatIconRegistry,
  ) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    this.isMobile$ = breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(map((result: BreakpointState) => result.matches));

    this.routerEventsSubscription = router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        withLatestFrom(this.isMobile$),
      )
      // eslint-disable-next-line
      .subscribe(([_, isMobile]) => {
        notificationService.dismiss();

        if (isMobile) {
          this.sidenav?.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.routerEventsSubscription?.unsubscribe();
  }
}
