import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'validDate' })
export class ValidDatePipe implements PipeTransform {
  transform(value: string | null): string {
    if (!value || value === '0001-01-01') {
      return '';
    }

    return value;
  }
}
