import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Attachment,
  PrefixListResponse,
  AttachmentListResponse,
  FileUploadResponse,
} from '@models';
import { Observable } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(
    private http: HttpClient,
    private dateAdapter: DateAdapter<Date>,
  ) {}

  getPrefixes(
    datasetId: string,
    skip: number,
    take: number,
    filter?: {
      q: string;
      prefix: string;
    },
  ): Observable<PrefixListResponse> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/prefix/`;
    let params = new HttpParams().set('skip', skip).set('take', take);

    if (filter) {
      if (filter.q) {
        params = params.set('q', filter.q);
      }

      if (filter.prefix) {
        params = params.set('prefix', filter.prefix);
      }
    }

    return this.http.get<PrefixListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }

  getAttachments(
    datasetId: string,
    skip: number,
    take: number,
    count: boolean,
    filter?: {
      q: string;
      from: Date | null;
      until: Date | null;
      prefix: string;
    },
    order?: { column: string; direction: 'asc' | 'desc' }[],
  ): Observable<AttachmentListResponse> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/attachment/`;
    let params = new HttpParams().set('skip', skip).set('take', take);

    if (count) {
      params = params.set('count', count);
    }

    if (filter) {
      if (filter.q) {
        params = params.set('q', filter.q);
      }

      if (filter.from) {
        params = params.set('from', this.dateAdapter.format(filter.from, null));
      }

      if (filter.until) {
        params = params.set(
          'until',
          this.dateAdapter.format(filter.until, null),
        );
      }

      if (filter.prefix) {
        params = params.set('prefix', filter.prefix);
      }
    }

    for (const o of order || []) {
      params = params.append('order', `${o.column}:${o.direction}`);
    }

    return this.http.get<AttachmentListResponse>(endpoint, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: params,
    });
  }

  createAttachment(
    datasetId: string,
    files: FileList,
  ): Observable<FileUploadResponse[]> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/attachment/`;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file && !(file.name === '.' && file.type === '')) {
        formData.append('blob', file);
      }
    }

    return this.http.post<FileUploadResponse[]>(endpoint, formData);
  }

  updateAttachment(
    datasetId: string,
    attachmentId: string,
    attachment: Attachment,
  ): Observable<Attachment> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/attachment/${attachmentId}`;

    return this.http.put<Attachment>(endpoint, attachment, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  deleteAttachment(datasetId: string, attachmentId: string): Observable<void> {
    const endpoint = `${environment.kinkoHost}/dataset/${datasetId}/attachment/${attachmentId}`;
    return this.http.delete<void>(endpoint);
  }
}
