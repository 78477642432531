<h2 mat-dialog-title>Advanced search</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field appearance="outline" class="search-form__search-input">
      <mat-label>Filename/title</mat-label>
      <input matInput type="text" formControlName="search" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="fromPicker" formControlName="from" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="fromPicker"></mat-datepicker-toggle>
      <mat-datepicker #fromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Until</mat-label>
      <input matInput [matDatepicker]="untilPicker" formControlName="until" />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="untilPicker"></mat-datepicker-toggle>
      <mat-datepicker #untilPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Prefix</mat-label>
      <input matInput type="text" formControlName="prefix" />
      @if (form.controls['prefix'].hasError('leadingslash')) {
        <mat-error>Prefix must start with '/'</mat-error>
      } @else if (form.controls['prefix'].hasError('trailingslash')) {
        <mat-error>Prefix must end with '/'</mat-error>
      }
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="submit" [disabled]="!form.valid">
      Search
    </button>
    <button mat-button type="button" (click)="onCancel()"> Cancel </button>
  </mat-dialog-actions>
</form>
