<h2 mat-dialog-title>File upload</h2>

<mat-dialog-content>
  @if (isLoading) {
    <p>Your files are being uploaded. Please wait...</p>
  } @else {
    <p
      >The following files failed to upload. Please try again or contact an
      administrator.</p
    >

    <mat-list>
      @for (file of files; track file.id) {
        <mat-list-item>
          <span matListItemTitle>{{ file.name }}</span>
          <span matListItemIcon>
            <mat-icon class="npdc-error-icon">error_circle</mat-icon>
          </span>
        </mat-list-item>
      }
    </mat-list>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (isLoading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  } @else {
    <button mat-button type="button" [mat-dialog-close]="true">Close</button>
  }
</mat-dialog-actions>
