import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadResponse } from '@models';
import { delay, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class AttachmentUploadDialogComponent implements OnInit, OnDestroy {
  public files: { id: number; name: string; error: string }[];
  public isLoading: boolean = true;

  private uploadResponseSubscription: Subscription | null;

  constructor(
    public dialogRef: MatDialogRef<AttachmentUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      uploadResponses: Observable<FileUploadResponse[]>;
    },
  ) {
    this.files = [];
    this.uploadResponseSubscription = null;
  }

  ngOnInit(): void {
    this.uploadResponseSubscription = this.data.uploadResponses
      .pipe(delay(1000))
      .subscribe({
        next: (responses: FileUploadResponse[]) => {
          let hasErrors = false;

          for (let i = 0; i < responses.length; i++) {
            const response = responses[i];
            if (!response.errorMessage) {
              continue;
            }

            hasErrors = true;

            this.files.push({
              id: i,
              name: response.fileName,
              error: response.errorMessage,
            });
          }

          if (hasErrors) {
            this.isLoading = false;
            console.error(this.files);
          } else {
            this.dialogRef.close();
          }
        },
        error: () => {
          this.dialogRef.close();
        },
      });
  }

  ngOnDestroy(): void {
    this.uploadResponseSubscription?.unsubscribe();
  }
}
