import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dataset-create-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class DatasetCreateFormComponent {
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<DatasetCreateFormComponent>) {
    this.form = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(1024),
      ]),
    });
  }

  public onSubmit() {
    if (this.form.pristine || !this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value.title);
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
