<div class="npdc-table">
  <div class="npdc-table__actions">
    <div class="npdc-table__actions__left">
      @if (!selection.hasValue()) {
        <button mat-flat-button (click)="openForm()">Add</button>
      } @else {
        @if (selection.selected.length === 1) {
          <button mat-flat-button (click)="openForm(selection.selected[0])"
            >Edit</button
          >
        }
        <button mat-button (click)="onDelete()">Delete</button>
      }
    </div>
  </div>

  <div class="npdc-table__table">
    @if (!dataSource.data.length) {
      <div class="npdc-empty-list"> There are no items to diplay. </div>
    } @else {
      <mat-selection-list
        [multiple]="selection.isMultipleSelection()"
        (selectionChange)="onListSelectionChange($event)">
        @for (element of dataSource.data; track element; let isLast = $last) {
          <mat-list-option
            togglePosition="before"
            class="list-item"
            [value]="element"
            [selected]="selection.isSelected(element)">
            <span matListItemTitle
              >{{ element.firstName }} {{ element.lastName }}</span
            >
            <span matListItemLine
              ><b>Roles</b>: {{ element.roles | roleListString }}</span
            >
            @if (element.organisation) {
              <span matListItemLine
                ><b>Organisation</b>: {{ element.organisation }}</span
              >
            }
            @if (element.email) {
              <span matListItemLine
                ><b>Email</b>:
                <a
                  href="mailto:{{ element.email }"
                  target="_blank"
                  appStopPropagation
                  >{{ element.email }}</a
                ></span
              >
            }
            @if (element.orcid) {
              <span matListItemLine><b>ORCID</b>: {{ element.orcid }}</span>
            }
            @if (element.url) {
              <span matListItemLine
                ><b>URL</b>:
                <a
                  appStopPropagation
                  href="{{ element.url }}"
                  target="_blank"
                  rel="nofollow"
                  >{{ element.url }}</a
                ></span
              >
            }
          </mat-list-option>
          <!--since the maximum amount of list rows (3) is exceeded in this list, we have to insert a line break-->
          @if (isLast === false) {
            <br />
          }
        }
      </mat-selection-list>
    }
  </div>
</div>
