import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  Dataset,
  DatasetContent,
  FormState,
  NetworkState,
  Timeframe,
} from '@models';
import { DatasetFormTimeframeFormComponent } from '../form/form.component';
import { BehaviorSubject } from 'rxjs';
import { DatasetService, NotificationService } from '@services';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-dataset-form-timeframe-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class DatasetFormTimeframeListComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  @Input({ required: true })
  networkState!: BehaviorSubject<NetworkState>;

  public dataSource: MatTableDataSource<Timeframe>;
  public selection: SelectionModel<Timeframe>;

  constructor(
    private dialog: MatDialog,
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.dataSource = new MatTableDataSource<Timeframe>();
    this.selection = new SelectionModel<Timeframe>(true, []);
  }

  ngOnInit() {
    this.dataSource.data.push(...(this.dataset.content.timeframes || []));
    this.updateValidity();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  toggleAllRows() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public openForm(timeframe?: Timeframe | null) {
    const dialogRef = this.dialog.open(DatasetFormTimeframeFormComponent, {
      data: timeframe,
      disableClose: true,
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((updatedTimeframe: Timeframe | null) => {
      if (!updatedTimeframe) {
        return;
      }

      const datasetContent = {
        ...this.dataset.content,
      };

      datasetContent.timeframes = [...(datasetContent.timeframes || [])];

      if (timeframe) {
        const index = datasetContent.timeframes.indexOf(timeframe);
        if (index !== -1) {
          datasetContent.timeframes[index] = updatedTimeframe;
        }
      } else {
        datasetContent.timeframes.push(updatedTimeframe);
      }

      this.updateDataset(datasetContent);
    });
  }

  public onDelete() {
    const timeframes = [];
    if (this.dataset.content?.timeframes?.length) {
      for (const timeframe of this.dataset.content.timeframes) {
        const index = this.selection.selected.indexOf(timeframe);
        if (index === -1) {
          timeframes.push(timeframe);
        }
      }
    }

    const datasetContent = {
      ...this.dataset.content,
    };

    if (!timeframes.length) {
      delete datasetContent.timeframes;
    } else {
      datasetContent.timeframes = timeframes;
    }

    this.updateDataset(datasetContent);
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.networkState.next(NetworkState.Pending);

    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataSource.data = [...(datasetContent.timeframes || [])];
          this.dataset.content = datasetContent;
          this.selection.clear();
          this.networkState.next(NetworkState.Success);
          this.updateValidity();
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to save changes.');
          this.networkState.next(NetworkState.Error);
        },
      });
  }

  onListSelectionChange(event: MatSelectionListChange) {
    for (const option of event.options) {
      if (option.selected) {
        this.selection?.select(option.value);
      } else {
        this.selection?.deselect(option.value);
      }
    }
  }

  private updateValidity() {
    if (this.dataSource.data.length) {
      this.formState.next(FormState.Valid);
    } else {
      this.formState.next(FormState.Invalid);
    }
  }
}
