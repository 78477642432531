import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'compactNumber' })
export class CompactNumberPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined || value < 0) {
      return '';
    }

    const labels: string[] = ['K', 'M', 'B', 'T'];
    for (let i = labels.length; i > 0; i--) {
      if (value < Math.pow(1000, i)) {
        continue;
      }

      return `${(value / Math.pow(1000, i)).toFixed(2)}${labels[i - 1]}`;
    }

    return `${value}`;
  }
}
