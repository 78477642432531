import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Dataset, DatasetContent, FormState, NetworkState } from '@models';
import { DatasetService, NotificationService } from '@services';
import { BehaviorSubject } from 'rxjs';
import * as geojson from 'geojson';
import { LeafletComponent } from '@components/leaflet/leaflet.component';

@Component({
  selector: 'app-dataset-form-geocoverage',
  templateUrl: './geocoverage.component.html',
  styleUrls: ['./geocoverage.component.scss'],
})
export class DatasetFormGeocoverageComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;

  @Input({ required: true })
  formState!: BehaviorSubject<FormState>;

  @Input({ required: true })
  networkState!: BehaviorSubject<NetworkState>;

  @ViewChild('leafletComponent')
  leafletComponent!: LeafletComponent;

  public geojson$: BehaviorSubject<geojson.FeatureCollection | null>;

  constructor(
    private datasetService: DatasetService,
    private notificationService: NotificationService,
  ) {
    this.geojson$ = new BehaviorSubject<geojson.FeatureCollection | null>(null);
  }

  ngOnInit() {
    if (this.dataset?.content?.geojson) {
      this.geojson$.next(structuredClone(this.dataset.content.geojson));
    }
  }

  onChange(geojson: geojson.FeatureCollection) {
    const datasetContent = {
      ...this.dataset.content,
    };

    if (!geojson?.features.length) {
      delete datasetContent.geojson;
    } else {
      datasetContent.geojson = geojson;
    }

    this.updateDataset(datasetContent);
  }

  private updateDataset(datasetContent: DatasetContent) {
    this.networkState.next(NetworkState.Pending);

    this.datasetService
      .updateDataset(this.dataset.id, datasetContent)
      .subscribe({
        next: () => {
          this.dataset.content = datasetContent;
          this.networkState.next(NetworkState.Success);
          this.updateValidity();
        },
        error: (err) => {
          console.error(err);
          this.networkState.next(NetworkState.Error);

          const issues: null | { type: string }[] =
            err?.error?.details?.issues || null;

          if (issues !== null && issues.length) {
            for (const issue of issues) {
              switch (issue.type) {
                case 'InvalidGeoJSON': {
                  this.notificationService.error('Invalid geometry.');
                  break;
                }
                default: {
                  this.notificationService.error('Failed to save changes.');
                  break;
                }
              }
            }
          } else {
            this.notificationService.error('Failed to save changes.');
          }

          this.leafletComponent.reset(
            true,
            structuredClone(this.dataset?.content?.geojson || null),
          );
        },
      });
  }

  private updateValidity() {
    if (this.dataset.content.geojson?.features.length) {
      this.formState.next(FormState.Valid);
    } else {
      this.formState.next(FormState.Invalid);
    }
  }
}
