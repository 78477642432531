@if (dataset$ | async; as dataset) {
  @if (isReadOnly) {
    <div class="npdc-file-list">
      @if ((isMobile$ | async) === false) {
        <form [formGroup]="form" class="file-search-form">
          <app-search
            formControlName="search"
            [isLoading$]="isLoading$"
            placeholder="Search in files"
            class="npdc-search-form__input">
            <button
              mat-icon-button
              matTooltip="Advanced search"
              (click)="openAdvancedSearch()"
              ><mat-icon>tune</mat-icon></button
            >
          </app-search>
        </form>
      } @else {
        <span class="npdc-file-list__mobile__search">
          <button mat-icon-button (click)="openAdvancedSearch()">
            <mat-icon>search</mat-icon>
          </button>
        </span>
      }
      <div class="npdc-file-list__mode">
        <span>
          <mat-button-toggle-group>
            <mat-button-toggle
              [checked]="(isDirectoryView$ | async) !== false"
              (click)="isDirectoryView$.next(true)">
              <mat-icon>folder_data</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
              [checked]="(isDirectoryView$ | async) === false"
              (click)="isDirectoryView$.next(false)">
              <mat-icon>list</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </span>
        <span>
          <a
            mat-icon-button
            download
            href="{{ fileHostUrl }}/dataset/{{
              dataset.id
            }}/attachment/_blob?zip{{ queryParams }}"
            title="Download selection as ZIP"
            [appAuthorize]="dataset.type !== DatasetType.Public">
            <mat-icon>download</mat-icon>
          </a>
        </span>
      </div>
    </div>
  }

  @if (
    form.value.until ||
    form.value.from ||
    (form.value.prefix && (isDirectoryView$ | async) === false)
  ) {
    <div class="npdc-filter-list">
      <mat-chip-set>
        @if (form.value.from; as value) {
          <mat-chip highlighted>
            From: {{ value | date: 'YYYY-MM-dd' }}
            <mat-icon matChipRemove (click)="form.patchValue({ from: null })"
              >cancel</mat-icon
            >
          </mat-chip>
        }
        @if (form.value.until; as value) {
          <mat-chip highlighted>
            Until: {{ value | date: 'YYYY-MM-dd' }}
            <mat-icon matChipRemove (click)="form.patchValue({ until: null })"
              >cancel</mat-icon
            >
          </mat-chip>
        }
        @if (form.value.prefix; as value) {
          <mat-chip highlighted>
            Prefix: {{ value }}
            <mat-icon matChipRemove (click)="form.patchValue({ prefix: null })"
              >cancel</mat-icon
            >
          </mat-chip>
        }
      </mat-chip-set>

      <button
        mat-button
        (click)="form.patchValue({ from: null, until: null, prefix: null })"
        >Clear filters</button
      >
    </div>
  }

  <div class="npdc-table">
    @if (isDirectoryView$ | async) {
      @if (prefixTree$ | async; as prefixTree) {
        <div class="npdc-table__actions npdc-prefix-header">
          <div class="npdc-table__actions__left">
            <button
              mat-button
              class="npdc-prefix-header__back"
              [disabled]="form.value.prefix === '/'"
              (click)="
                form.patchValue({
                  prefix: prefixTree.get(form.value.prefix)?.prev,
                })
              ">
              <mat-icon matListItemIcon>arrow_back</mat-icon>
            </button>
            @if ((isMobile$ | async) === false) {
              <button
                mat-icon-button
                [disabled]="form.value.prefix === '/'"
                (click)="form.patchValue({ prefix: '/' })">
                <mat-icon class="npdc-prefix-icon">folder_open</mat-icon>
              </button>
              @if (form.value.prefix === '/') {
                <strong>{{ form.value.prefix }}</strong>
              } @else {
                @for (
                  crumb of prefixBreadCrumb | keyvalue;
                  track crumb.key;
                  let isFirst = $first;
                  let isLast = $last
                ) {
                  <strong>/</strong>
                  <button
                    mat-button
                    [disabled]="isLast"
                    (click)="form.patchValue({ prefix: crumb.key })">
                    {{ truncate(crumb.value) }}
                  </button>
                }
              }
            }
          </div>
          <div class="npdc-table__actions__right">
            @if (prefixTree.get(form.value.prefix)?.size !== null) {
              <em>
                {{
                  prefixTree.get(form.value.prefix)?.size | humanReadableSize
                }}
                -
                {{ prefixTree.get(form.value.prefix)?.count | compactNumber }}
                Files
              </em>
            }
          </div>
        </div>
      }
    }

    <div class="npdc-table__actions">
      @if (!isReadOnly) {
        <div class="npdc-table__actions__left">
          <input
            #fileInput
            type="file"
            title="blob"
            name="blob"
            placeholder="Select one or more files to upload"
            multiple
            hidden
            (change)="onFileInputChange($event, dataset.id)" />
          <input
            #dirInput
            type="file"
            title="blob"
            name="blob"
            placeholder="Select directory to upload"
            webkitdirectory
            multiple
            hidden
            (change)="onFileInputChange($event, dataset.id)" />
          @if (selection) {
            @if (!selection.hasValue()) {
              <button mat-flat-button (click)="fileInput.click()">Add</button>
              <button mat-flat-button (click)="dirInput.click()"
                >Add directory</button
              >
            }
            @if (selection.hasValue() && selection.selected.length === 1) {
              <button mat-flat-button (click)="openForm(selection.selected[0])"
                >Edit</button
              >
            }
            @if (canDelete && selection.hasValue()) {
              <button mat-button (click)="onDelete()">Delete</button>
            }
          }
        </div>
      }
    </div>

    @if (attachments$ | async; as attachments) {
      @if (selection) {
        @if (attachments.length === 0) {
          <div class="npdc-empty-list"> There are no items to diplay. </div>
        }

        <mat-selection-list
          [multiple]="selection.isMultipleSelection()"
          (selectionChange)="onListSelectionChange($event)">
          @for (element of attachments; track element.id; let isLast = $last) {
            <mat-list-option
              togglePosition="before"
              class="list-item"
              [value]="element"
              [selected]="selection.isSelected(element)">
              <span matListItemTitle>{{
                element.title || element.filename
              }}</span>
              <span matListItemLine>
                <strong>Prefix:</strong>
                {{ element.prefix }}
              </span>
              <span matListItemLine>
                <strong>Released:</strong>
                {{ element.released | validDate | date: 'YYYY-MM-dd' }}
                <strong>Size:</strong>
                {{ element.byteSize | humanReadableSize }}
              </span>
            </mat-list-option>
          }
        </mat-selection-list>
      } @else {
        <div class="attachment-list">
          <mat-action-list>
            @if (prefixTree$ | async; as prefixTree) {
              @if (
                (isDirectoryView$ | async) !== false &&
                  prefixTree.get(form.value.prefix);
                as path
              ) {
                @for (
                  prefix of path.dir | keyvalue;
                  track prefix.key;
                  let isLast = $last
                ) {
                  <button
                    mat-list-item
                    (click)="form.patchValue({ prefix: prefix.key })">
                    <mat-icon matListItemIcon>folder</mat-icon>
                    <span matListItemTitle>
                      <strong>{{ prefix.value }}</strong>
                    </span>
                    <span matListItemLine>
                      <strong>Files:</strong>
                      {{ prefixTree.get(prefix.key)?.count }}
                    </span>
                    <span matListItemLine>
                      <strong>Size:</strong>
                      {{ prefixTree.get(prefix.key)?.size | humanReadableSize }}
                    </span>
                  </button>
                }
              } @else if (attachments.length === 0) {
                <div class="npdc-empty-list">
                  There are no items to diplay.
                </div>
              }
            }

            @for (
              element of attachments;
              track element.id;
              let isLast = $last
            ) {
              <button
                mat-list-item
                (click)="
                  openItem(element, dataset.type === DatasetType.Public)
                ">
                <mat-icon matListItemIcon>description</mat-icon>
                <span matListItemTitle>
                  <a
                    target="_blank"
                    href="{{ fileHostUrl }}/dataset/{{
                      element.datasetId
                    }}/attachment/{{ element.id }}/_blob"
                    appStopPropagation
                    [appAuthorize]="
                      dataset.type !== DatasetType.Public ||
                      !isPublicAttachment(element)
                    "
                    >{{ element.title || element.filename }}</a
                  >
                </span>
                <span matListItemLine>
                  <strong>Prefix:</strong>
                  {{ element.prefix }}
                </span>
                <span matListItemLine>
                  <strong>Released:</strong>
                  {{ element.released | validDate | date: 'YYYY-MM-dd' }}
                  <strong>Size:</strong>
                  {{ element.byteSize | humanReadableSize }}
                </span>
              </button>
            }
          </mat-action-list>
        </div>
      }
    }

    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100, 200]"
      (page)="loadPage($event, dataset.id, false)"
      showFirstLastButtons></mat-paginator>
  </div>
}
