<div class="search-bar">
  <div
    class="search-bar__overlay-origin"
    cdkOverlayOrigin
    #origin="cdkOverlayOrigin"></div>

  <app-search
    (focusChange)="$event.isFocused && datasets.length && openOverlay()"
    class="search-bar__input"
    [formControl]="searchFormControl"
    [style.opacity]="(isOverlayOpen$ | async) ? '0' : 'inherit'"
    [isLoading$]="isLoading$"></app-search>
</div>

<ng-template
  cdkConnectedOverlay
  (detach)="closeOverlay()"
  (backdropClick)="closeOverlay()"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
  ]"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="!!(isOverlayOpen$ | async)">
  <div class="npdc-overlay" cdkTrapFocus cdkTrapFocusAutoCapture>
    <app-search
      class="search-bar__input search-bar__input__with-results"
      [formControl]="searchFormControl"
      [isLoading$]="isLoading$"></app-search>

    <mat-action-list class="search-bar__results">
      @for (
        dataset of datasets;
        track dataset.id;
        let isLast = $last, idx = $index
      ) {
        @if (getCitation(dataset); as citation) {
          <mat-divider></mat-divider>
          <a
            mat-list-item
            lines="3"
            (click)="closeOverlay()"
            routerLink="/dataset/{{ dataset.id }}"
            [class.bottom]="isLast">
            <mat-icon matListItemIcon>dataset</mat-icon>
            <span matListItemTitle>{{ dataset.content.title }}</span>
            <span matListItemLine
              ><i>{{ citation.authors || '[No authors]' }}</i></span
            >
            @if (citation.published) {
              <span matListItemLine
                >{{ citation.publicationYear }} - {{ citation.publisher }}</span
              >
            }
            @if (dataset.type !== 'public') {
              <div matListItemMeta>
                @switch (dataset.type) {
                  @case ('draft') {
                    <mat-chip>Draft</mat-chip>
                  }
                  @case ('internal') {
                    <mat-chip>Internal</mat-chip>
                  }
                }
              </div>
            }
          </a>
        }
      }
    </mat-action-list>
  </div>
</ng-template>
